import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAllItemsAction, deleteItemAction } from '../actions/itemActions';
import LoadImage from '../components/LoadImage';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Paginate from '../components/Paginate';

function ItemListScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const { userInfo } = useSelector(state => state.loginUser);
  const { loading, items, error, page, pages } = useSelector(state => state.getAllItems);
  const { loading: deleteLoading, response, error: errorDelete } = useSelector(state => state.deleteItem);

  let keyword = location.search;
  
  const childurl = location.pathname.split('/').length === 3 &&
    location.pathname.split('/')[2]
  
  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      dispatch(deleteItemAction(id));
    }
  }
  
  useEffect(() => {
    ((userInfo && userInfo.isadmin) || response) ? dispatch(getAllItemsAction(keyword)) : navigate('/');
    return () => {
      response && dispatch({ type: 'delete_item_reset' });
    }
  }, [dispatch, navigate, userInfo, response, keyword]);
  
  return (
    <div>
      <div style={{ width: 'fit-content' }}>
        <button type="button" onClick={() => navigate('/rootuser/itemlist/0')} title='Create New Item' style={{ right: '10rem' }}
          className="btn btn-sm btn-outline-info bg-gradient position-absolute">
          Create New Item
        </button>
      </div>
      <h1 className='text-center my-2 h2 fst-italic text-light'>Admin Items List</h1>
      <div className="col border-secondary container mt-3">
        <div className="table-responsive">
          {deleteLoading && <Loader/>}
          {errorDelete && <Message variant={'danger'}>{errorDelete.message}</Message>}
          {response && <Message variant={'warning'}>{response}</Message>}
          {
            loading ? <Loader /> : (error ? <Message variant={'danger'}>{error.message}</Message> : (
              <table className="table table-striped table-bordered">
                <thead>
                  <tr className='text-success text-center'>
                    <th scope="col">ID</th>
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th scope="col">Category</th>
                    <th scope="col">SubCategory</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    items.map(i => (
                      <tr key={i._id}>
                        <td>{i._id}</td>
                        <td>
                          <LoadImage src={`/static${i.image}`} height={40} />
                        </td>
                        <td>{i.title}</td>
                        <td>{i.category.title}</td>
                        <td>{i.subcattitle}</td>
                        <td>{i.brandtitle}</td>
                        <td>{i.price}</td>
                        <td className='col-2 text-center pt-3'>
                          <Link to={`/rootuser/itemlist/${i._id}`} title='Edit Item' className='me-3'>
                            <button type="button"
                              style={{ padding: '0.2rem', fontSize: '0.9em' }}
                              className="btn btn-sm btn-outline-success bg-gradient">
                              <i className="bi bi-pencil me-1"/>
                              Edit
                            </button>
                          </Link>
                          <button type="button" title='Delete Item'
                            onClick={() => deleteHandler(i._id)}
                            onMouseUp={(e) => e.target.blur()}
                            style={{ padding: '0.1rem 0.2rem 0.2rem 0.2rem', fontSize: '0.9em' }}
                            className="btn btn-sm btn-outline-danger bg-gradient">
                            <i className="bi bi-trash3 me-1"/>
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            ))
          }
        </div>
      </div>
      <Paginate
        page={page} pages={pages} keyword={keyword}
        isadmin={true} childurl={childurl ? childurl : false} />
    </div>
  )
}

export default ItemListScreen;