import { createSlice } from "@reduxjs/toolkit";

export const getBrandsSlice = createSlice({
  name: 'getBrands',
  initialState: {brands: [], maxprice: ''},
  extraReducers: (builder) => {
    builder
      .addCase('get_brands_request', (state) => {
        state.loading = true
      })
      .addCase('get_brands_success', (state, action) => {
        state.loading = false
        state.brands = action.payload.brands
        state.maxprice = action.payload.biggerprice
      })
      .addCase('get_brands_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      /* .addCase('review_create_reset', () => {
        return {}
      }) */
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const brandItemsSlice = createSlice({
  name: 'brandItems',
  initialState: {items: {}},
  extraReducers: (builder) => {
    builder
      .addCase('brand_items_request', (state) => {
        state.loading = true
      })
      .addCase('brand_items_success', (state, action) => {
        state.loading = false
        state.items = action.payload
      })
      .addCase('brand_items_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('brand_items_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const updateBrandSlice = createSlice({
  name: 'updateBrand',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('update_brand_request', (state) => {
        state.loading = true
      })
      .addCase('update_brand_success', (state, action) => {
        state.loading = false
        state.success = action.payload
      })
      .addCase('update_brand_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('update_brand_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const createBrandSlice = createSlice({
  name: 'createBrand',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('create_brand_request', (state) => {
        state.loading = true
      })
      .addCase('create_brand_success', (state, action) => {
        state.loading = false
        state.successCreate = action.payload && true
      })
      .addCase('create_brand_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('create_brand_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const deleteBrandSlice = createSlice({
  name: 'deleteBrand',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('delete_brand_request', (state) => {
        state.loading = true
      })
      .addCase('delete_brand_success', (state, action) => {
        state.loading = false
        state.response = action.payload
      })
      .addCase('delete_brand_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('delete_brand_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});