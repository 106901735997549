import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { filterItemsAction } from '../actions/categoryActions.js';
import Item from '../components/Item';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Filter from '../components/Filter';

function FilteredItemsScreen() {
  const dispatch = useDispatch();
  const navigateBack = useNavigate();
  const location = useLocation();
  const { loading, filtered, error } = useSelector(state => state.filterItems);
  
  useEffect(() => {
    if (!location.state) {
      navigateBack(-1)
    } else {
      const { finbrand, finrating, finrange } = location.state;
      dispatch(filterItemsAction({ finbrand, finrating, finrange }));
    }
  }, [dispatch, navigateBack, location])
  
  return (
    <div className='px-5 py-2'>
      <div className='mb-3 ms-3'>
        <button type="button" onClick={() => navigateBack(-1)} title='Go Back' style={{ left: '2rem' }}
          className="btn btn-sm btn-outline-success bg-gradient position-absolute">
          <i className="bi bi-arrow-left" /> Back
        </button>
      </div>
      <Filter />
      <h1 className={`text-center mb-5 h2 catname ${filtered.length === 0 ? 'text-warning' : 'text-light fst-italic'}`}>
        {filtered.length === 0 ? 'No items by filter' : 'Items by filter'}
      </h1>
      {
        loading ? <Loader /> : (
          error ? <Message variant={'dnager'}>{error}</Message> : (
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-4 g-lg-3 g-md-2 justify-content-center'>
              {
                filtered[0] && filtered.map(i => (
                  <div className='col catblock' key={i._id}>
                    <Item item={i} subcat={true} />
                  </div>
                ))
              }
            </div>
          )
        )
      }
    </div>
  )
}

export default FilteredItemsScreen;