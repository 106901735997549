import axios from "axios";
import { createAction } from "@reduxjs/toolkit";

export const createOrder = (order) => createAction(null, async(dispatch, getState) => {
  try {
    dispatch({ type: 'order_create_request' });
    
    const { loginUser: { userInfo } } = getState();
    
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.post(`/api/orders/add/`, order, config);
    
    dispatch({
      type: 'order_create_success',
      payload: data
    });
    
    dispatch({
      type: 'clear_cart_items',
      payload: data
    });
    
    localStorage.removeItem('cartItems');
    localStorage.removeItem('paymentMethod');
    localStorage.removeItem('shippingAddress');
  
  } catch (e) {
    dispatch({
      type: 'order_create_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

export const getOrderDetails = (id) => createAction(null, async (dispatch, getState) => {
  try {
    dispatch({ type: 'order_details_request' });
    
    const { loginUser: { userInfo } } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.get(`/api/orders/${id}/`, config);
    
    dispatch({
      type: 'order_details_success',
      payload: data
    });
    
  } catch (e) {
    dispatch({
      type: 'order_details_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

export const listMyOrders = () => createAction(null, async (dispatch, getState) => {
  try {
    dispatch({ type: 'order_list_request' });
    
    const { loginUser: { userInfo } } = getState();
    
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.get(`/api/orders/myorders/`, config);
    
    dispatch({
      type: 'order_list_success',
      payload: data
    });
    
  } catch (e) {
    dispatch({
      type: 'order_list_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message
    });
  }
});

export const payOrderAction = (id) => createAction(null, async (dispatch, getState) => {
  try {
    dispatch({ type: 'order_pay_request' });
    
    const { loginUser: { userInfo } } = getState();
    
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.patch(`/api/orders/${id}/pay/`, userInfo, config);
    
    dispatch({
      type: 'order_pay_success',
      payload: data
    });
    
  } catch (e) {
    dispatch({
      type: 'order_pay_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message
    });
  }
});

export const deliverOrderAction = (order) => createAction(null, async (dispatch, getState) => {
  try {
    dispatch({ type: 'order_deliver_request' });
    
    const { loginUser: { userInfo } } = getState();
    
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.post(`/api/orders/${order._id}/deliver/`, userInfo, config);
    
    dispatch({
      type: 'order_deliver_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'order_deliver_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message
    });
  }
});

// List orders for admin panel
export const listOrdersAction = (keyword) => createAction(null, async (dispatch, getState) => {
  try {
    dispatch({ type: 'list_orders_request' });
    
    const { loginUser: { userInfo } } = getState();
    
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.get(`/api/orders/${keyword}`, config);
    
    dispatch({
      type: 'list_orders_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'list_orders_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message
    });
  }
});