import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import PreHeader from './components/PreHeader'
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import CategoryScreen from './screens/CategoryScreen';
import SubcategoryScreen from './screens/SubcategoryScreen';
import ItemScreen from './screens/ItemScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import CartScreen from './screens/CartScreen';
import ShippingScreen from './screens/ShippingScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import ProfileScreen from './screens/ProfileScreen';
import BrandScreen from './screens/BrandScreen';
import NewCollectionScreen from './screens/NewCollectionScreen';
import FilteredItemsScreen from './screens/FilteredItemsScreen';
import UserListScreen from './screens/UserListScreen';
import ItemListScreen from './screens/ItemListScreen';
import OrderListScreen from './screens/OrderListScreen';
import BrandListScreen from './screens/BrandListScreen';
import CategoryListScreen from './screens/CategoryListScreen';
import CategoryEditScreen from './screens/CategoryEditScreen';
import UserEditScreen from './screens/UserEditScreen';
import ItemEditScreen from './screens/ItemEditScreen';
import SubCategoryListScreen from './screens/SubCategoryListScreen';
import SubCategoryEditScreen from './screens/SubCategoryEditScreen';
import BrandEditScreen from './screens/BrandEditScreen';
import SearchScreen from './screens/SearchScreen';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

function App() {
  const [winheight, setWinheight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWinheight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize);
    
    // remove `brands values` from session storage all time when reload page
    window.addEventListener('beforeunload', (e) => {
      sessionStorage.removeItem('brands')
    }, false);

    return () => {
      window.removeEventListener('resize', handleResize)
      
      // remove `brands values` from session storage 
      window.onbeforeunload = (e) => {
        return null
      }
    };
  })
  
  return (
    <BrowserRouter>
      <Header />
      <PreHeader />
      <main className='d-flex flex-column' style={{ minHeight: `${winheight - 350}px` }}>
        <Routes>
          <Route path='/' element={<HomeScreen />} />
          <Route>
            <Route path=':catname' element={<CategoryScreen />} />
            <Route path=':catname/:subcatname' element={<SubcategoryScreen />} />
            <Route path=':catname/:subcatname/:id' element={<ItemScreen />} />
          </Route>
          <Route path='login' element={<LoginScreen />} />
          <Route path='register' element={<RegisterScreen />} />
          <Route path='profile' element={<ProfileScreen />} />
          <Route>
            <Route path='cart' element={<CartScreen />} />
            <Route path='cart/:id' element={<CartScreen />} />
          </Route>
          <Route path='shipping' element={<ShippingScreen />} />
          <Route path='payment' element={<PaymentScreen />} />
          <Route path='placeorder' element={<PlaceOrderScreen />} />
          <Route path='/order/:id' element={<OrderScreen />} />
          <Route path=':brandname/items' element={<BrandScreen />} />
          <Route path='newcollection' element={<NewCollectionScreen />} />
          <Route path='filtereditems' element={<FilteredItemsScreen />} />
          <Route path='search' element={<SearchScreen />} />
          <Route path='/rootuser'>
            <Route path='userlist' element={<UserListScreen />}/>
            <Route path='userlist/:id' element={<UserEditScreen />} />
            <Route path='itemlist' element={<ItemListScreen />} />
            <Route path='itemlist/:id' element={<ItemEditScreen />} />
            <Route path='orderlist' element={<OrderListScreen />} />
            <Route path='brandlist' element={<BrandListScreen />} />
            <Route path='brandlist/:id' element={<BrandEditScreen />} />
            <Route path='categorylist' element={<CategoryListScreen />} />
            <Route path='categorylist/:id' element={<CategoryEditScreen />} />
            <Route path='subcategorylist' element={<SubCategoryListScreen />} />
            <Route path='subcategorylist/:id' element={<SubCategoryEditScreen />} />
          </Route>
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
