import axios from "axios";
import { createAction } from "@reduxjs/toolkit";

export const addtoCart = (id, qty) => createAction(null, async (dispatch, getState) => {
  
  const { data } = await axios.get(`/api/items/${id}`);
  
  dispatch({
    type: 'cart_add_item',
    payload: {
      _id: data._id,
      title: data.title,
      image: data.image,
      price: data.price,
      countInStock: data.countInStock,
      qty,
      catSlug: data.category.slug,
      subcatSlug: data.subcatslug
    }
  })
  
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
});

export const removeFromCart = (id) => createAction(null, (dispatch, getState) => {
  
  dispatch({
    type: 'cart_remove_item',
    payload: id
  })
  
  getState().cart.cartItems.length > 0
    ? localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
    : localStorage.removeItem('cartItems');
  // localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
  
  return Object;
});

export const saveShippingAddress = (data) => createAction(null, (dispatch) => {
  // const { address, city, postalCode, country } = data;
  dispatch({
    type: 'save_shipping_address',
    payload: data
  })

  localStorage.setItem('shippingAddress', JSON.stringify(data));
  return Object;
});

export const savePaymentMethod = (data) => createAction(null, (dispatch) => {
  dispatch({
    type: 'save_payment_method',
    payload: data
  })
  
  localStorage.setItem('paymentMethod', JSON.stringify(data));
  return Object;
})