import { createSlice } from "@reduxjs/toolkit";

import { listCategories, getAllCategoryByNameAction, getSubcatItems } from "../actions/categoryActions";

export const categoryListSlice = createSlice({
  name: 'categoryList',
  initialState: { category: [] },
  extraReducers: (builder) => {
    builder
      .addCase(listCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(listCategories.fulfilled, (state, action) => {
        state.category = action.payload;
        state.loading = false;
      })
      .addCase(listCategories.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addDefaultCase((state) => {
        return state;
      })
  }
});

export const getAllCategoryByNameSlice = createSlice({
  name: 'getAllCategoryByName',
  initialState: { list: [] },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategoryByNameAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCategoryByNameAction.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading = false;
      })
      .addCase(getAllCategoryByNameAction.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addDefaultCase((state) => {
        return state;
      })
  }
});

export const getSubcatItemsSlice = createSlice({
  name: 'getSubcatItems',
  initialState: { list: [] },
  extraReducers: (builder) => {
    builder
      .addCase(getSubcatItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubcatItems.fulfilled, (state, action) => {
        state.list = action.payload.item;
        state.page = action.payload.page
        state.pages = action.payload.pages
        state.loading = false;
      })
      .addCase(getSubcatItems.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addDefaultCase((state) => {
        return state;
      })
  }
});

export const filterItemsSlice = createSlice({
  name: 'filterItems',
  initialState: { filtered: {} },
  extraReducers: (builder) => {
    builder
      .addCase('filter_items_request', (state) => {
        state.loading = true
      })
      .addCase('filter_items_success', (state, action) => {
        state.loading = false
        state.filtered = action.payload
      })
      .addCase('filter_items_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('filter_items_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const updateCategorySlice = createSlice({
  name: 'updateCategory',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('update_category_request', (state) => {
        state.loading = true
      })
      .addCase('update_category_success', (state, action) => {
        state.loading = false
        state.success = action.payload
      })
      .addCase('update_category_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('update_category_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const createCategorySlice = createSlice({
  name: 'createCategory',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('create_category_request', (state) => {
        state.loading = true
      })
      .addCase('create_category_success', (state, action) => {
        state.loading = false
        state.successCreate = action.payload && true
      })
      .addCase('create_category_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('create_category_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const deleteCategorySlice = createSlice({
  name: 'deleteCategory',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('delete_category_request', (state) => {
        state.loading = true
      })
      .addCase('delete_category_success', (state, action) => {
        state.loading = false
        state.response = action.payload
      })
      .addCase('delete_category_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('delete_category_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

// subCategory for admin panel
export const updateSubCategorySlice = createSlice({
  name: 'updateSubCategory',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('update_subcategory_request', (state) => {
        state.loading = true
      })
      .addCase('update_subcategory_success', (state, action) => {
        state.loading = false
        state.success = action.payload
      })
      .addCase('update_subcategory_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('update_subcategory_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const createSubCategorySlice = createSlice({
  name: 'createSubCategory',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('create_subcategory_request', (state) => {
        state.loading = true
      })
      .addCase('create_subcategory_success', (state, action) => {
        state.loading = false
        state.successCreate = action.payload && true
      })
      .addCase('create_subcategory_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('create_subcategory_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const deleteSubCategorySlice = createSlice({
  name: 'deleteSubCategory',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('delete_subcategory_request', (state) => {
        state.loading = true
      })
      .addCase('delete_subcategory_success', (state, action) => {
        state.loading = false
        state.response = action.payload
      })
      .addCase('delete_subcategory_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('delete_subcategory_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});