import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getBrandsAction } from '../actions/brandActions.js';

function Filter() {
  const [showmenu, setShowmenu] = useState(false);
  const [range, setRange] = useState(0);
  const [brand, setBrand] = useState([]);
  const [rating, setRating] = useState(0);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // object with rating values
  const ratingvals = {
    1: 'Poor', 2: 'Fair', 3: 'Good', 4: 'Very Good', 5: 'Excellent', 
  }
  
  const { brands, maxprice } = useSelector(state => state.getBrands);
  // const { loading, filtered, error } = useSelector(state => state.filterItems);
  
  const onMouseLeave = (e) => {
    e.preventDefault();
    if (window.innerWidth > 960) {
      setTimeout(() => {
        setShowmenu(false)
      }, 300)
      clearTimeout();
    }
  }
  
  const submitHandler = (e) => {
    e.preventDefault();
    if (brand.length === 0 && rating === 0 && range === 0) {
      return false
    } else {
      let finbrand = brand.length > 0 ? brand : brands.map((b) => b.title);
      let finrating = rating > 0 ? rating : 5;
      let finrange = range > 0 ? range : maxprice.price__max;
      
      onMouseLeave(e);
      setBrand([]);
      setRating(0);
      setRange(0);
      navigate('/filtereditems', {state: {finbrand, finrating, finrange}});
    }
  }
  
  // Remains in selected state (checkbox is checked) until to submit form
  const checkBrands = (brandsarray, brandtitle) => {
    return brandsarray.includes(brandtitle) ? true : false
  }
  
  // Remains in selected state radiobutton for rating
  const checkRatings = (rating, rkey) => {
    return (rating === Number(rkey)) ? true : false
  }
  
  // console.log(error);

  useEffect(() => {
    brands.length === 0 && dispatch(getBrandsAction());
    
    const handler = (e) => {
      if (showmenu && ref.current && !ref.current.contains(e.target)) {
        setShowmenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showmenu, dispatch, brands])
  
  return (
    <div>
      <div className='position-absolute d-block filterblock'
        style={{ right: '7rem', transform: 'translateY(-1rem)', zIndex: `${showmenu ? '1000' : 'auto'}` }}>
        <div className='' ref={ref}>
          <button type="button" style={{ fontSize: '0.8rem' }} title="Filter Items"
            aria-expanded={showmenu ? "true" : "false"}
            onClick={() => setShowmenu((prev) => !prev)} onMouseUp={(e) => e.target.blur()}
            className="btn btn-sm btn-outline-success bg-gradient p-1">
            <i className="bi bi-filter me-md-1 me-sm-3" />Filter Items
          </button>
          {
            showmenu && (
              <div className='filteritems bg-primary' onMouseLeave={onMouseLeave}>
                <form onSubmit={submitHandler}>
                  <fieldset>
                    <div className="accordion d-block mx-auto" id="accordionExample" style={{ width: '90%' }}>
                      <div className="accordion-item border-0 bg-primary">
                        <h6 className="accordion-header" id="headingOne">
                          <button className="accordion-button collapsed bg-primary border-bottom border-1 border-light" type="button" data-bs-toggle="collapse"
                            onMouseUp={(e) => e.target.blur()} style={{ fontSize: '0.9rem' }}
                            data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            By Brand
                          </button>
                        </h6>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body" style={{ paddingLeft: '2rem' }}>
                            {
                              brands.map((b) => (
                                <div className="form-check" key={b._id}>
                                  <input className="form-check-input checkinput" type="checkbox"
                                    name={b.slug}
                                    value={b.title} id="flexCheckDefault"
                                    /* checked={checkBrands(brand, b.title)} */
                                    defaultChecked={checkBrands(brand, b.title)}
                                    onChange={e => setBrand([...brand, e.target.value])} />
                                  <label className="form-check-label" htmlFor="flexCheckDefault">
                                    {b.title}
                                  </label>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item border-0 bg-primary">
                        <h6 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed bg-primary border-bottom border-1 border-light"
                            type="button" data-bs-toggle="collapse"
                            onMouseUp={(e) => e.target.blur()} style={{ fontSize: '0.9rem' }}
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            By Rating
                          </button>
                        </h6>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body" style={{ paddingLeft: '2rem' }}>
                            {
                              Object.keys(ratingvals).map((r) => (
                                <div className="form-check" key={r}>
                                  <input className="form-check-input checkinput" type="radio" name="optionsRadios" id="optionsRadios2"
                                    defaultChecked={checkRatings(rating, r)}
                                    /* checked={checkRatings(rating, r)} */
                                    value={r} onChange={(e) => setRating(Number(e.target.value))} />
                                  <label className="form-check-label" htmlFor="optionsRadios2">
                                    &nbsp;&nbsp;{r} - {ratingvals[r]}
                                  </label>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-group d-block mx-auto mt-3 px-2' style={{ width: '90%' }}>
                      <label htmlFor="customRange3" className="form-label" style={{ display: 'initial' }}>
                        <span style={{ fontSize: '0.91rem' }}>By Price</span> <br />
                        <span className='d-block float-start mt-2' style={{ fontSize: '0.91rem' }}>from $0</span>
                        <span className='float-end mt-2 mb-1' style={{ fontSize: '0.91rem' }}>
                          to ${range ? range : 0}
                        </span>
                      </label>
                      <input onChange={(e) => setRange(Number(e.target.value))}
                        type="range" className="form-range rangeinput" defaultValue={range ? range : 0}
                        min="0" max={maxprice.price__max} step="1" id="customRange3" />
                    </div>
                    
                    <button type="submit" onMouseUp={(e) => e.target.blur()}
                      className="btn btn-sm btn-success bg-gradient d-block mx-auto mb-3 mt-1 submitbutton">
                      Submit
                    </button>
                  </fieldset>
                </form>

              </div>
            )
          }
        </div>
      </div>
      
    </div>
  )
}

export default Filter;
// export default React.memo(Filter);