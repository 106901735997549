import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { loginUser } from '../actions/userActions';
import Message from '../components/Message';
import Loader from '../components/Loader';

function LoginScreen() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
  
  const redirect = location.search ? location.search.split('=')[1] : '/';
  
  const { userInfo, loading, error } = useSelector(state => state.loginUser);
  
  /* error && console.log(error);
  userInfo && console.log(userInfo); */
  
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(loginUser({ email, password })).then(() => { ref.current.reset(); });
  }
  
  useEffect(() => {
    userInfo && navigate(redirect);
  }, [navigate, userInfo, redirect]);
  
  return (
    <div>
      <h1 className='text-center my-2 h2'>Login Page</h1>
      {loading ? <Loader /> : (
        <div className='mx-auto loginblock' style={{ width: '30%' }}>
          <form onSubmit={submitHandler} ref={ref}>
            <fieldset>
              <div className="form-group">
                <label htmlFor="inputEmail" className="form-label mt-4 text-muted">Enter Email Address</label>
                <input type="email" className="form-control text-secondary" id="inputEmail"
                  style={{ fontSize: '0.95rem' }}
                  value={email} onChange={(e) => setEmail(e.target.value.trim())}
                  placeholder="Enter email" />
              </div>
              <div className="form-group">
                <label htmlFor="inputPassword" className="form-label mt-4 text-muted">Password</label>
                <input type="password" className="form-control text-secondary"
                  style={{ fontSize: '0.95rem' }}
                  value={password} onChange={(e) => setPassword(e.target.value)}
                  id="inputPassword" placeholder="Password" />
              </div>
            </fieldset>
            <button type="submit" className="btn btn-info bg-gradient mt-4 d-block mx-auto">
              Sign In
            </button>
          </form>
        </div>
      )}
      <div className='col registerlink text-center mt-4'>
        New Customer?&nbsp;
        <Link to={'/register'} className='text-decoration-none'>Register</Link>
      </div>
      {error && <Message variant={'danger'}>{ error.message }</Message>}
    </div>
  )
}

export default LoginScreen;