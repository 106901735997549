import React from 'react';
import { Link } from 'react-router-dom';

function Paginate(props) {
  /* 
    props.pages - amount of pages
    props.page - current page
    props.keyword - (**optional) keyword from location.search, i.e in current case is - `?page=2`
    props.isadmin - constant for checking if user is `admin` for creating is right path.
          In this case, exmaple - `/rootuser/orderlist`.
    props.childurl - second word after slash. In this case from example above `orderlist`.
    props.chr - current character by default is `?`, but can be `&`
  */
  
  const rootuser = props.isadmin ? 'rootuser/' : '';
  let finchar = props.chr ? props.chr : '?';
  
  return (
    <div>
      <div className='d-flex justify-content-center mt-3'>
        <ul className="pagination pagination-sm">
          {
            props.page > 1 ? (
              <li className="page-item" onMouseUp={(e) => e.target.blur()}>
                <Link to={`/${rootuser}${props.childurl && props.childurl}${finchar}page=${props.page - 1}`}
                  className="page-link">&laquo;</Link>
              </li>
            ): (
              <li className="page-item disabled">
                  <Link to={'#'} className="page-link">&laquo;</Link>
              </li> 
            )
          }
          {
            [...Array(props.pages).keys()].map((x) => (
              <li className={`page-item ${x + 1 === props.page && 'active'}`} key={x + 1}
                onMouseUp={(e) => e.target.blur()}>
                <Link to={`/${rootuser}${props.childurl && props.childurl}${finchar}page=${x + 1}`}
                  className="page-link">
                  {x + 1}
                </Link>
              </li>
            ))
          }
          {
            props.page < props.pages ? (
              <li className="page-item" onMouseUp={(e) => e.target.blur()}>
                <Link to={`/${rootuser}${props.childurl && props.childurl}${finchar}page=${props.page + 1}`}
                  className="page-link">
                  &raquo;
                </Link>
              </li>
            ): (
              <li className="page-item disabled">
                <Link to={'#'} className="page-link">&raquo;</Link>
              </li> 
            )
          }
        </ul>
      </div>
    </div>
  )
}

export default React.memo(Paginate);