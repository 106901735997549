import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { itemDetail, updateItemAction, createItemAction } from '../actions/itemActions';
import Message from '../components/Message';
import Loader from '../components/Loader';
import LoadImage from '../components/LoadImage';

function ItemEditScreen() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [uploadimage, setUploadimage] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [brand, setBrand] = useState('');
  const [newcollection, setNewcollection] = useState(false);
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [countInStock, setCountInStock] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [ressuccess, setrRessuccess] = useState('');

  const { 
    loading: loadingitem, list, error: erroritem
  } = useSelector(state => state.itemDetail);
  const { 
    loading: updateItemLoading, success, error: errorUpdate
  } = useSelector(state => state.updateItem);

  const {
    loading: successItemLoading, successCreate, error: errorSuccessCreate
  } = useSelector(state => state.createItem);
  
  const submitHandler = (e) => {
    e.preventDefault();
    if (Number(id) !== 0) {
      dispatch(updateItemAction({
        _id: id, title, image, category, subcategory, brand,
        newcollection, description, price, countInStock
      }));
    } else {
      dispatch(createItemAction({
        title, image, category, subcategory, brand,
        newcollection, description, price, countInStock
      }));
    }
  };
  
  const uploadFileHandler = async(e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    
    formData.append('image', file);
    formData.append('id', id);
    
    setUploading(true);
    
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    
    await axios.post(
      '/api/items/upload/', formData, config
    ).then(res => {
      setUploadimage(res.data.response);
      setImage(res.data.image);
    }).catch(err => {
      setUploadimage(err);
    })
    setUploading(false);
  }

  useEffect(() => {
    if (Number(id) !== 0) {
      if (success) {
        dispatch({ type: 'update_item_reset' });
        setrRessuccess('Item was updated!');
      } else {
        if (!list.title || list._id !== Number(id)) {
          dispatch(itemDetail(id));
        } else {
          setTitle(list.title);
          setImage(list.image);
          setCategory(list.category.title);
          setSubcategory(list.subcattitle);
          setBrand(list.brandtitle);
          setNewcollection(list.newcollection);
          setDescription(list.description);
          setPrice(list.price);
          setCountInStock(list.countInStock);
        }
      }
    } else {
      if (successCreate) {
        dispatch({ type: 'create_item_reset' });
        navigate('/rootuser/itemlist');
      }
    }
  }, [dispatch, id, list, navigate, success, successCreate]);
  
  return (
    <div>
      <div style={{ width: 'fit-content' }}>
        <button type="button" onClick={() => navigate(-1)} title='Go Back' style={{ left: '3rem' }}
          className="btn btn-sm btn-outline-success bg-gradient position-absolute">
          <i className="bi bi-arrow-left" /> Back
        </button>
      </div>
      <h1 className='text-center my-2 h2 fst-italic text-light'>Admin Edit Item</h1>
      <div className='col-8 col-xxl-5 mx-auto'>
        {successItemLoading && <Loader />}
        {errorSuccessCreate && <Message variant={'danger'}>{errorSuccessCreate.message}</Message>}
        {ressuccess.length > 0 && <Message variant={'success'}>{ressuccess}</Message>}
        {updateItemLoading && <Loader/>}
        {errorUpdate && <Message variant={'danger'}>{errorUpdate.message}</Message>}
        {
          loadingitem ? <Loader /> : (
            erroritem ? <Message variant={'danger'}>{erroritem.message}</Message> : (
              <form onSubmit={submitHandler}>
                <fieldset>
                  <div className="form-group edititeminpt">
                    <label htmlFor="itemtitle" className="form-label mt-4 text-muted">Title</label>
                    <input type="name" className="form-control text-secondary" id="itemtitle"
                      value={title} onChange={(e) => setTitle(e.target.value.trimEnd())}
                      placeholder="Item Title" />
                  </div>
                  <div className="form-group edititeminpt col-5">
                    <label htmlFor="formFile" className="form-label mt-4 text-muted">
                      {
                        uploading ? <Loader /> : (
                          uploadimage ? uploadimage : 'Upload Image'
                        )
                      }
                      {
                        image ? (
                          <span className='ms-2 float-end' style={{ cursor: 'pointer' }}>
                            <LoadImage src={`/static${image}`} height={40} className='me-2' />
                            {image}
                          </span>
                        ) : (
                          <span className='ms-2 float-end' style={{ cursor: 'pointer' }}>
                            <LoadImage src='/static/images/placeholder.png' height={40} />
                          </span>
                        )
                      }
                    </label>
                    <input className="form-control text-secondary"
                      type="file" id="formFile" onChange={uploadFileHandler} />
                  </div>
                  <div className="form-group edititeminpt">
                    <label htmlFor="category" className="form-label mt-4 text-muted">Category</label>
                    <input type="text" className="form-control text-secondary" id="category"
                      value={category} onChange={(e) => setCategory(e.target.value.trim())}
                      placeholder="Category" />
                  </div>
                  <div className="form-group edititeminpt">
                    <label htmlFor="subcategory" className="form-label mt-4 text-muted">Subcategory</label>
                    <input type="text" className="form-control text-secondary" id="subcategory"
                      value={subcategory} onChange={(e) => setSubcategory(e.target.value.trim())}
                      placeholder="Subcategory" />
                  </div>
                  <div className="form-group edititeminpt">
                    <label htmlFor="brand" className="form-label mt-4 text-muted">Brand</label>
                    <input type="text" className="form-control text-secondary" id="brand"
                      value={brand} onChange={(e) => setBrand(e.target.value.trim())}
                      placeholder="Brand" />
                  </div>
                  <div className="form-check mt-4 edititeminpt">
                    <input className="form-check-input" type="checkbox" id="newcollection"
                      checked={newcollection} onChange={(e) => setNewcollection(e.target.checked)} />
                    <label className="form-check-label text-muted" htmlFor="newcollection">New Collection</label>
                  </div>
                  <div className="form-group edititeminpt">
                    <label htmlFor="description" className="form-label mt-3 text-muted">Description</label>
                    <textarea value={description} onChange={(e) => setDescription(e.target.value)}
                      className="form-control text-secondary" id="description" rows="5" />
                  </div>
                  <div className="form-group edititeminpt col-3">
                    <label htmlFor="price" className="form-label mt-4 text-muted">Price</label>
                    <input type="number" className="form-control text-secondary"
                      value={price} onChange={(e) => setPrice(e.target.value)}
                      id="price" placeholder="Price" />
                  </div>
                  <div className="form-group edititeminpt col-3">
                    <label htmlFor="countInStock" className="form-label mt-4 text-muted">Count in Stock</label>
                    <input type="number" className="form-control text-secondary"
                      value={countInStock} onChange={(e) => setCountInStock(e.target.value)}
                      id="countInStock" placeholder="Count in Stock" />
                  </div>
                </fieldset>
                <button type="submit" className="btn btn-info bg-gradient mt-4 d-block mx-auto">
                  Submit
                </button>
              </form>
            )
          ) 
        }
      </div>
    </div>
  )
}

export default ItemEditScreen;