import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import LoadImage from '../components/LoadImage';
import CheckoutSteps from '../components/CheckoutSteps';
import { createOrder } from '../actions/orderActions';
import Loader from '../components/Loader';
import Message from '../components/Message';

function PlaceOrderScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const cart = useSelector(state => state.cart);
  
  const itemsPrice = cart.cartItems.reduce(
    (acc, item) => acc + item.price * item.qty, 0).toFixed(2);
  const shippingPrice = (Number(itemsPrice) > 100 ? 0 : 10).toFixed(2);
  const taxPrice = Number((0.082) * itemsPrice).toFixed(2);
  const totalPrice = (
    Number(itemsPrice) + Number(shippingPrice) + Number(taxPrice)).toFixed(2);
  
  const { loading, order, error } = useSelector(state => state.orderCreate);
  
  useEffect(() => {
    !cart.paymentMethod && navigate('/payment');
    
    if (!error && loading === false) {
      navigate(`/order/${order._id}`);
      dispatch({ type: 'order_create_reset' });
    }
    
  }, [dispatch, cart, navigate, error, loading, order])
  
  const placeOrder = () => {
    dispatch(createOrder({
      orderItems: cart.cartItems,
      shippingAddress: cart.shippingAddress,
      paymentMethod: cart.paymentMethod,
      itemsPrice: itemsPrice,
      shippingPrice: shippingPrice,
      taxPrice: taxPrice,
      totalPrice: totalPrice
    }));
  }
  
  return (
    <div>
      <CheckoutSteps step1 step2 step3 step4 />
      <h1 className='text-center mt-2 mb-4 h2'>Place Order</h1>
      
      <div className='row container mx-auto'>
        <div className="col-md-8">
          <div className='list-group-flush'>
            <div className='list-group-item mb-2'>
              <div className="col my-2">
                <h4 className='mb-4'>Shipping</h4>
                <strong className='text-muted'>Shipping: </strong> 
                {cart.shippingAddress.address}, {cart.shippingAddress.city},
                {cart.shippingAddress.postalCode}, {cart.shippingAddress.country}
              </div>
            </div>
            <div className='list-group-item mb-2'>
              <div className="col my-2">
                <h4 className='mb-4'>Payments Methods</h4>
                <strong className='text-muted'>Method: </strong> 
                {cart.paymentMethod}
              </div>
            </div>
            <div className='list-group-item my-3'>
              <h4 className='mb-3'>Order items</h4>
              <strong className='mb-4 d-block text-muted'>Products: </strong>
              {
                cart.cartItems.map((item, index) => (
                  <div className='row mb-3 pb-2' style={{ borderBottom: '1px dotted var(--bs-light)' }} key={index}>
                    <div className="col-2">
                      <LoadImage src={`/static${item.image}`}
                        className='img' style={{ maxWidth: '80%', height: 'auto' }} />
                      {/* <img className="img"
                          style={{ maxWidth: '70%', height: 'auto' }}
                          src={`/static/${i.image}`}
                          alt="" /> */}
                    </div>
                    <div className="col-md-4 mt-1">
                      <Link to={`/${item.catSlug}/${item.subcatSlug}/${item._id}`} target="_blank"
                        className="small text-decoration-none cat-header-link">{item.title}</Link>
                    </div>
                    <div className="col-md-2 mt-2">{item.price}$</div>
                    <div className="col-md-3 mt-2">
                      Count Items:&nbsp;
                      <strong className='text-success'>{item.qty}</strong>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        
        <div className="col-md-4">
          <div className="card pb-3">
            <div className="list-group list-group-flush">
              <div className="list-group-item text-center">
                <h4 className="mb-4">Order Summary</h4>
                <div className="row border-bottom border-success mt-3">
                  <div className="col">Items:</div>
                  <div className="col">{itemsPrice}$</div>
                </div>
                <div className="row border-bottom border-success mt-3">
                  <div className="col">Shipping price:</div>
                  <div className="col">{shippingPrice}$</div>
                </div>
                <div className="row border-bottom border-success mt-3">
                  <div className="col">Tax:</div>
                  <div className="col">{taxPrice}$</div>
                </div>
                <div className="row border-bottom border-success mt-3">
                  <div className="col">Total:</div>
                  <div className="col">{totalPrice}$</div>
                </div>
              </div>
              {
                loading ? <Loader /> : (
                  <div className={`${cart.cartItems.length === 0 ? 'd-none' : 'text-center list-group-item mt-3'}`}>
                    <button type="button" className="btn btn-info bg-gradient" onClick={placeOrder}>
                      Place Order
                    </button>
                  </div>
                )
              }
            </div>
          </div>
          { error && <Message variant={'danger'}>{error.message}</Message> }
        </div>
      </div>
    </div>
  )
}

export default PlaceOrderScreen;