import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { itemDetail, reviewItemAction } from '../actions/itemActions';
import Loader from '../components/Loader';
import LoadImage from '../components/LoadImage';
import Message from '../components/Message';
import Rating from '../components/Rating';

function ItemScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [reviewresponse, setReviewresponse] = useState('');
  const [qty, setQty] = useState(1);

  const { list, loading, error } = useSelector(state => state.itemDetail);
  const {
    loading: reviewLoading, response, error: reviewError
  } = useSelector(state => state.reviewItem);
  const { userInfo } = useSelector(state => state.loginUser);
  
  if (window.location.hash.includes('anchor')) {
    setTimeout(() => {
      document.getElementById('anchor')?.scrollIntoView();
    }, 150)
    clearTimeout();
  }
  
  const submitHandler = (e) => {
    e.preventDefault();
    setReviewresponse('');
    
    const colors = [
      'primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'
    ];
    let squarecolor = colors[Math.floor(Math.random() * colors.length)];
    
    dispatch(reviewItemAction(id, { squarecolor, rating, comment }));
    e.target.reset();

    setTimeout(() => {
      document.getElementById('anchor')?.scrollIntoView();
    }, 150)
    clearTimeout();
  }
  
  const addToCart = () => {
    navigate(`/cart/${id}?qty=${qty}`);
  }
  
  useEffect(() => {
    window.scrollTo(0, 0);
    
    if (response) {
      setRating(0)
      setComment('')
      setReviewresponse(response)
      dispatch({ type: 'review_create_reset' });
    }
    
    dispatch(itemDetail(id));
    
    return () => {
      reviewError && dispatch({ type: 'review_create_reset' });
    }
    
  }, [dispatch, id, reviewLoading, response, reviewError]);
  
  return (
    <div className='px-lg-5 py-2 px-md-1'>
      <div className='mb-3 ms-2'>
        <button type="button" onClick={() => navigate(-1)} style={{ left: '2rem' }}
          className="btn btn-sm btn-outline-success bg-gradient position-absolute" title='Go Back'>
          <i className="bi bi-arrow-left" /> Back
        </button>
      </div>
      {
        loading ? <Loader /> : (
          error ? <Message variant={'danger'}>{error}</Message> :
            list && (
              <div className='row justify-content-center px-3' key={list._id}>
                <h1 className='text-center mb-3 h2 px-5 catname'>{list.title}</h1>
                <div className='col-md-3 w-auto'>
                  {list.image && <LoadImage src={`/static${list.image}`} />}
                  {/* <img src={list.image && `/static${list.image}`}
                alt={list.image && list.image.split('.')[0].split('/').pop()} /> */}
                </div>
                <div className='col-md-8'>
                  <div className='mb-4 catname'>
                    <p className='mb-2'>Reviews: <strong>{list.numReviews}</strong></p>
                    <Rating rating={list.rating} />
                  </div>
                  <div className='pe-3 articletext'>
                    <p style={{ whiteSpace: 'pre-line' }}>{list.description}</p>
                  </div>
                  <div className="mt-5 col-md-6">
                    <div className="card">
                      {
                        list.countInStock > 0 ? (
                          <div className="list-list-group-horizontal bg-body text-center">
                            <div className="list-group-item border-0 border-bottom border-dark text-success">
                              <div className="row">
                                <div className="col">Price:</div>
                                <div className="col" style={{ fontWeight: '600', letterSpacing: '0.03rem' }}>{`$${list.price}`}</div>
                              </div>
                            </div>
                            <div className="list-group-item border-0 border-bottom border-dark text-success">
                              <div className="row">
                                <div className="col">Status:</div>
                                <div className="col" style={{ fontWeight: '600', letterSpacing: '0.03rem' }}>
                                  In Stock
                                </div>
                              </div>
                            </div>
                            <div className="list-group-item border-0 border-bottom border-dark text-success">
                              <div className="row">
                                <div className="col">Qty:</div>
                                <div className="col-4 mx-4">
                                  <select onChange={(e) => setQty(e.target.value)}
                                    className="me-sm-3 form-select form-select-sm text-success fw-bolder">
                                    {
                                      [...Array(list.countInStock).keys()].map((q) => (
                                        <option key={q + 1} value={q + 1}>{q + 1}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="text-center list-group-item">
                              <button type="button" className="btn-block my-2 btn btn-primary" onClick={addToCart}>
                                Add to Cart
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="list-list-group-horizontal bg-body text-center">
                            <div className="list-group-item border-0 border-bottom border-dark text-success">
                              <div className="row">
                                <div className="col">Price:</div>
                                <div className="col"
                                  style={{ fontWeight: '600', letterSpacing: '0.03rem' }}>{`$${list.price}`}</div>
                              </div>
                            </div>
                            <div className="list-group-item text-success">
                              <div className="row">
                                <div className="col">Status:</div>
                                <div className="col" style={{ fontWeight: '600', letterSpacing: '0.03rem' }}>
                                  Out Of Stock
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>

                <div className="my-3 p-3 bg-body rounded shadow-sm border-top mt-5">
                  <div className="list-group list-group-flush">
                    <div id="anchor"></div>
                    <div className="list-group-itemw w-50 d-block mx-auto reviewblock">
                      <h5 className="text-center mt-3">Write Review</h5>
                      {reviewLoading && <Loader />}
                      {reviewresponse && <Message variant={'success'}>{reviewresponse}</Message>}
                      {reviewError && <Message variant={'danger'}>{reviewError}</Message>}
                      {
                        !userInfo
                          ? <Message variant={'warning'}>
                            Please&nbsp;
                            <Link to={'/login'} className='info-link text-decoration-none mx-1'><strong>Login</strong></Link>
                            to write review
                          </Message>
                          : (
                            <div className='mt-0'>
                              <form onSubmit={submitHandler}>
                                <fieldset>
                                  <div className="form-group">
                                    <label htmlFor='selectRating' className="form-label mt-3">Rating</label>
                                    <select value={rating}
                                      onChange={(e) => setRating(Number(e.target.value))}
                                      id="selectRating" className="form-select">
                                      <option disabled="" value='' hidden="">Select...</option>
                                      <option value="1">1 - Poor</option>
                                      <option value="2">2 - Fair</option>
                                      <option value="3">3 - Good</option>
                                      <option value="4">4 - Very Good</option>
                                      <option value="5">5 - Excellent</option>
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="exampleTextarea" className="form-label mt-3">Comment</label>
                                    <textarea value={comment} onChange={(e) => setComment(e.target.value)}
                                      id="exampleTextarea" rows="4" className="form-control"></textarea>
                                  </div>
                                </fieldset>
                                <button type="submit"
                                  onMouseUpCapture={(e) => e.target.blur()}
                                  className="btn btn-outline-info bg-gradient mt-4 d-block mx-auto">
                                  Submit
                                </button>
                              </form>
                            </div>
                          )
                      }
                    </div>
                  </div>
                  <h6 className="border-bottom pb-2 mb-0 mt-4">Reviews</h6>
                  {
                    list.reviews && (
                      list.reviews.length === 0 ? <Message variant={'info'}>{'No Reviews'}</Message> : (
                        list.reviews && (
                          list.reviews.map((rev) => (
                            <div className="d-flex text-muted pt-3" key={rev._id}>
                              <div className={`bg-${rev.squarecolor} me-2 opacity-75`} style={{ width: '2rem', height: '2rem' }} />
                              <div className="pb-3 mb-0 small border-bottom" style={{ minWidth: '50%' }}>
                                <strong className="d-block text-gray-dark mb-1">@username - {rev.nameofuser}</strong>
                                <Rating rating={rev.rating} />
                                <span className='me-2' style={{ fontWeight: 500 }}><em>Comment:</em></span>{rev.comment}
                              </div>
                            </div>
                          ))
                        )
                      )
                    )
                  }
                </div>
              </div>
            )
        )
      }
    </div>
  )
}

export default React.memo(ItemScreen);