import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAllItemsAction } from '../actions/itemActions';
import Item from '../components/Item';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';

function SearchScreen() {
  const dispatch = useDispatch();
  const navigateBack = useNavigate();
  const location = useLocation();
  
  let keyword = location.search;
  const childurl = location.pathname.substring(1) + keyword.split('&')[0];
  
  const { loading, items, error, page, pages } = useSelector(state => state.getAllItems);
  
  useEffect(() => {
    dispatch(getAllItemsAction(keyword));
    return () => {
      dispatch({ type: 'get_allitems_reset'})
    }
  }, [dispatch, keyword, childurl]);
  
  return (
    <div className='px-5 py-2'>
      <div className='mb-3 ms-3'>
        <button type="button" onClick={() => navigateBack(-1)} title='Go Back' style={{ left: '2rem' }}
          className="btn btn-sm btn-outline-success bg-gradient position-absolute">
          <i className="bi bi-arrow-left" /> Back
        </button>
      </div>
      {
        items && (
          <div>
            <h1 className={`text-center mb-4 h2 catname ${
              (items.length === 0 && !loading) ? 'text-warning' : 'fst-italic text-light'}`}>
              {
                (items.length === 0 && !loading) ? 'Search results not found'
                  : `Search results for: ${location.search.substring(3).split('&')[0]}`
              }
            </h1>
            {
              loading ? <Loader /> : (
                error ? <Message variant={'dnager'}>{error.message}</Message> : (
                  <div className='row row-cols-1 row-cols-md-2 row-cols-lg-4 g-lg-3 g-md-2 justify-content-center'>
                    {
                      items.map((i) => {
                        return (
                          <div className='col catblock' key={i._id}>
                            <Item item={i} subcat={true} />
                          </div>
                        )
                      })
                    }
                  </div>
                )
              )
            }
          </div>
        )
      }
      
      {
        pages > 1 && (
          <Paginate
            page={page} pages={pages}
            chr={'&'}
            childurl={childurl ? childurl : false} />
        )
      }
    </div>
  )
}

export default SearchScreen;