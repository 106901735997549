import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { loginUser, registerUserAction } from '../actions/userActions';
import Message from '../components/Message';
import Loader from '../components/Loader';

function RegisterScreen() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const ref = useRef(null);
  const [errorState, setErrorstate] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  
  const redirect = location.search ? location.search.split('=')[1] : '/';

  const { userInfo, loading, error } = useSelector(state => state.registerUser);
  
  // (error && error.message) && console.log(error);
  
  useEffect(() => {
    error && setErrorstate(error.message);
    userInfo && dispatch(loginUser({ email, password })).then(() => {
      navigate(redirect)
    });
  }, [navigate, redirect, userInfo, dispatch, email, password, error]);
  
  const submitHandler = (e) => {
    e.preventDefault();
    (password !== confirmpassword)
      ? setErrorstate('Please check passwords values!')
      : (username.length === 0 || email.length === 0) ? setErrorstate('All fields must be required!')
        : dispatch(registerUserAction({ username, email, password })).then(() => { ref.current.reset(); });
  }
  
  return (
    <div>
      <h1 className='text-center my-2 h2'>Registration</h1>
      {
        loading ? <Loader /> : (
          <div className='mx-auto loginblock' style={{ width: '30%' }}>
            <form onSubmit={submitHandler} ref={ref}>
              <fieldset>
                <div className="form-group">
                  <label htmlFor="inputUsername" className="form-label mt-4 text-muted">Enter Username</label>
                  <input type="name" className="form-control text-secondary" id="inputUsername"
                    style={{ fontSize: '0.95rem' }}
                    value={username} onChange={(e) => setUsername(e.target.value.trimStart())}
                    placeholder="Enter Username" />
                </div>
                <div className="form-group">
                  <label htmlFor="inputEmail" className="form-label mt-4 text-muted">Enter Email Address</label>
                  <input type="email" className="form-control text-secondary" id="inputEmail"
                    style={{ fontSize: '0.95rem' }}
                    value={email} onChange={(e) => setEmail(e.target.value.trim())}
                    placeholder="Enter email" />
                </div>
                <div className="form-group">
                  <label htmlFor="inputPassword" className="form-label mt-4 text-muted">Password</label>
                  <input type="password" className="form-control text-secondary"
                    style={{ fontSize: '0.95rem' }}
                    value={password} onChange={(e) => setPassword(e.target.value)}
                    id="inputPassword" placeholder="Password" />
                </div>
                <div className="form-group">
                  <label htmlFor="inputConfirmpassword" className="form-label mt-4 text-muted">Confirm Password</label>
                  <input type="password" className="form-control text-secondary"
                    style={{ fontSize: '0.95rem' }}
                    value={confirmpassword} onChange={(e) => setConfirmpassword(e.target.value)}
                    id="inputConfirmpassword" placeholder="Confirm Password" />
                </div>
              </fieldset>
              <button type="submit" className="btn btn-info bg-gradient mt-4 d-block mx-auto">
                Register
              </button>
            </form>
          </div>
        )
      }
      <div className='col registerlink text-center mt-4'>
        Have an Account?&nbsp;
        <Link to={'/login'} className='text-decoration-none'>Sign In</Link>
      </div>
      {(errorState && errorState.length > 0) && <Message variant={'danger'}>{errorState}</Message>}
    </div>
  )
}

export default RegisterScreen;