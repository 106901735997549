import React from 'react';
import { Link } from 'react-router-dom';

import Rating from './Rating';
import LoadImage from './LoadImage';

function Item(props) {
  
  return (
    <div className="card mb-3 h-100">
      <h6 className="card-header text-center">
        <Link to={`/${props.item.category.slug}/${props.item.subcatslug}/${props.item._id}`}
          className='text-body text-decoration-none cat-header-link fst-italic' style={{ fontSize: '1.02em' }}>
          {props.item.title}
        </Link>
      </h6>
      <LoadImage src={`/static${props.item.image}`} className='d-block mx-auto mt-3'
        style={{ maxWidth: '30%', height: 'auto' }}/>
      {/* <img src={`/static${props.item.image}`} style={{ maxWidth: '30%', height: 'auto' }}
        className='d-block mx-auto mt-3' /> */}
      <div className="card-body d-flex align-items-end">
        <div>
          <p className="card-text" style={{ fontSize: '0.9em' }}>
            {props.item.description.replace(/\n||\t/g, '').split(' ', 30).join(' ') + '...'}
          </p>
          <p className="card-text mb-2 text-dark"
            style={{ fontSize: '0.92rem', fontWeight: '500' }}>Brand: {props.item.brandtitle}</p>
          <p className="card-text mb-2 text-dark"
            style={{ fontSize: '0.92rem', fontWeight: '500' }}>Price: ${props.item.price}</p>
          <Rating
            rating={props.item.rating} itemid={props.item._id}
            catslug={props.item.category.slug} subcatslug={props.item.subcatslug} />
        </div>
        
      </div>
      <div className="text-center mb-3">
        <Link className="btn btn-success bg-gradient fst-italic"
          to={`/${props.item.category.slug}/${props.item.subcatslug}/${props.item._id}`}>
          View Item
        </Link>
      </div>
    </div>
  )
}

export default Item;