import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { brandItemsAction } from '../actions/brandActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Item from '../components/Item';
import Filter from '../components/Filter';

function BrandScreen() {
  const { brandname } = useParams();
  const dispatch = useDispatch();
  const navigateBack = useNavigate();
  
  const { loading, items, error } = useSelector(state => state.brandItems);

  useEffect(() => {
    dispatch({ type: 'brand_items_reset' });
    dispatch(brandItemsAction(brandname));
  }, [dispatch, brandname])
  
  return (
    <div className='px-5 py-2'>
      <div className='mb-3 ms-3'>
        <button type="button" onClick={() => navigateBack(-1)} title='Go Back' style={{ left: '2rem' }}
          className="btn btn-sm btn-outline-success bg-gradient position-absolute">
          <i className="bi bi-arrow-left" /> Back
        </button>
      </div>
      <Filter />
      <h1 className='text-center mb-5 h2 catname'>
        {items && (items[0] && items[0].brandtitle)} Items
      </h1>
      {
        loading ? <Loader /> : (
          error ? <Message variant={'dnager'}>{error}</Message> : (
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-4 g-lg-3 g-md-2 justify-content-center'>
              {
                Array.from(items).map(i => (
                  <div className='col catblock' key={i._id}>
                    <Item item={i} />
                  </div>
                ))
              }
            </div>
          )
        )
      }
    </div>
  )
}

export default BrandScreen;
// export default React.memo(BrandScreen);