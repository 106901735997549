import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { getUserDetailsAction, updateUserProfileAction } from '../actions/userActions';
import { listMyOrders } from '../actions/orderActions';
import Loader from '../components/Loader';
import Message from '../components/Message';

function ProfileScreen() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [errorState, setErrorstate] = useState('');
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const { loading, user, error } = useSelector(state => state.userDetails);
  const { userInfo } = useSelector(state => state.loginUser);
  const { success } = useSelector(state => state.updateUserProfile);
  const {
    loading: loadingOrders, orders, error: errorOrders
  } = useSelector(state => state.orderListMy);

  const submitHandler = (e) => {
    e.preventDefault();

    (password !== confirmpassword)
      ? setErrorstate('Passwords do not match!')
      : !isEmail(email) ? setErrorstate('Wrong email!')
        : dispatch(updateUserProfileAction({
          'id': user._id,
          'name': name,
          'email': email,
          'password': password
        }))
  }
  
  const getuserProfile = useCallback(() => {
    if (userInfo._id !== user._id || !user.name || success) {
      dispatch({ type: 'reset_profile' });
      dispatch(getUserDetailsAction());
      dispatch(listMyOrders());
    } else if (error) {
      ref.current.reset();
      setErrorstate(error);
    } else {
      dispatch({ type: 'order_list_reset' });
      dispatch(listMyOrders());
    }
    setName(user.name || '');
    setEmail(user.email || '');
  }, [dispatch, userInfo, user._id, user.name, user.email, success, error])
  
  useEffect(() => {
    !userInfo ? navigate('login') : getuserProfile();
  }, [getuserProfile, navigate, userInfo])
  
  return (
    <div className='container-fluid row'>
      <div className='col-md-4'>
        <h3>User Profile</h3>
        <div>
          {
            loading ? <Loader /> : (
              <form onSubmit={submitHandler} ref={ref}>
                <fieldset>
                  <div className="form-group">
                    <label htmlFor="inputName" className="form-label text-muted">Enter Name</label>
                    <input type="name" className="form-control text-secondary" id="inputName"
                      required
                      style={{ fontSize: '0.95rem' }}
                      value={name} onChange={(e) => setName(e.target.value.trimStart())}
                      placeholder="Enter Name" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputEmail" className="form-label mt-4 text-muted">Enter Email Address</label>
                    <input type="email" className="form-control text-secondary" id="inputEmail"
                      required
                      style={{ fontSize: '0.95rem' }}
                      value={email} onChange={(e) => setEmail(e.target.value.trim())}
                      placeholder="Enter email" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputPassword" className="form-label mt-4 text-muted">Password</label>
                    <input type="password" className="form-control text-secondary"
                      style={{ fontSize: '0.95rem' }}
                      value={password} onChange={(e) => setPassword(e.target.value)}
                      id="inputPassword" placeholder="Password" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputConfirmpassword" className="form-label mt-4 text-muted">Confirm Password</label>
                    <input type="password" className="form-control text-secondary"
                      style={{ fontSize: '0.95rem' }}
                      value={confirmpassword} onChange={(e) => setConfirmpassword(e.target.value)}
                      id="inputConfirmpassword" placeholder="Confirm Password" />
                  </div>
                </fieldset>
                <button type="submit"
                  className="btn btn-outline-info bg-gradient mt-4 d-block mx-auto">
                  Update
                </button>
              </form>
            )
          }
        </div>
        {errorState.length > 0 && <Message variant={'danger'}>{errorState}</Message>}
      </div>
      <div className='col-md-8 border-start border-secondary ordersblock'>
        <h3>My Orders</h3>
        <div className="table-responsive">
          {
            loadingOrders ? <Loader /> : (
              <table className="table table-striped table-responsive table-sm">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Date</th>
                    <th scope="col">Total</th>
                    <th scope="col">Date Paid</th>
                    <th scope="col">Delivered</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    orders && (
                      orders.length > 0 && (
                        orders.map(order => (
                          <tr key={order._id}>
                            <td>{order._id}</td>
                            <td>{order.createdAt.substring(0, 10)}</td>
                            <td>{order.totalPrice}</td>
                            {
                              order.isPaid
                                ?
                                <td>
                                  {order.paidAt.substring(0, 10)}
                                  <i className="bi bi-shield-check text-success fs-5 ms-3" />
                                </td>
                                :
                                <td>
                                  Still not paid
                                  <i className="bi bi-shield-exclamation text-danger fs-5 ms-2" />
                                </td>
                            }
                            {
                              order.isDelivered
                                ?
                                <td>
                                  {order.deliveredAt.substring(0, 10)}
                                  <i className="bi bi-shield-check text-success fs-5 ms-4" />
                                </td>
                                :
                                <td>
                                  Not delivered
                                  <i className="bi bi-shield-exclamation text-danger fs-5 ms-2" />
                                </td>
                            }
                            <td>
                              <Link to={`/order/${order._id}`}>
                                <button type="button"
                                  style={{ padding: '0 0.2rem 0.2rem 0.2rem', fontSize: '1.05em' }}
                                  className="btn btn-outline-success bg-gradient">
                                  <i className="bi bi-binoculars me-1" />
                                  Details
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))
                      )
                    )
                    
                  }
                </tbody>
              </table>
            )
          }
        </div>
        {errorOrders && <Message variant={'danger'}>{errorOrders}</Message>}
      </div>
    </div>
  )
}

export default ProfileScreen