import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { listOrdersAction } from '../actions/orderActions';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Paginate from '../components/Paginate';

function OrderListScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { userInfo } = useSelector(state => state.loginUser);
  const { loading, orders, error, page, pages } = useSelector(state => state.listOrders);
  
  let keyword = location.search;
  
  const childurl = location.pathname.split('/').length === 3 &&
    location.pathname.split('/')[2]
  
  useEffect(() => {
    (userInfo && userInfo.isadmin) ? dispatch(listOrdersAction(keyword)) : navigate('/');
  }, [dispatch, navigate, userInfo, keyword]);

  return (
    <div>
      <h1 className='text-center my-2 h2 fst-italic text-light'>Admin Items List</h1>
      <div className="col border-secondary container mt-3">
        <div className="table-responsive">
          {
            loading ? <Loader /> : (error ? <Message variant={'danger'}>{error.message}</Message> : (
              <table className="table table-striped table-bordered">
                <thead>
                  <tr className='text-success text-center'>
                    <th scope="col">ID</th>
                    <th scope="col">User</th>
                    <th scope="col">Date</th>
                    <th scope="col">Total</th>
                    <th scope="col">Paid</th>
                    <th scope="col">Delivered</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {
                    orders.map(o => (
                      <tr key={o._id} className='text-center'>
                        <td>{o._id}</td>
                        <td>{o.user.name ? o.user.name : o.user.username}</td>
                        <td>{o.createdAt.substring(0, 10)}</td>
                        <td>{o.totalPrice}$</td>
                        <td>
                          {
                            o.isPaid ? (
                              o.paidAt.substring(0, 10)
                            ): (
                              <i className="bi bi-x-circle text-danger fs-4" />
                            )
                          }
                        </td>
                        <td>
                          {
                            o.isDelivered ? (
                              o.deliveredAt.substring(0, 10)
                            ): (
                              <i className="bi bi-x-circle text-danger fs-4"/>
                            )
                          }
                        </td>
                        <td className='col-2 text-center pt-3'>
                          <Link to={`/order/${o._id}`} title='Details' className='me-3'>
                            <button type="button"
                              style={{ padding: '0.2rem', fontSize: '0.9em' }}
                              className="btn btn-sm btn-outline-warning bg-gradient">
                              <i className="bi bi-binoculars me-1" />
                              Details
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            ))
          }
        </div>
      </div>
      <Paginate
        page={page} pages={pages} keyword={keyword}
        isadmin={true} childurl={childurl ? childurl : false}/>
    </div>
  )
}

export default OrderListScreen;