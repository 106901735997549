import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getNewcollectionAction } from '../actions/itemActions';
import Item from '../components/Item';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Filter from '../components/Filter';

function NewCollectionScreen() {
  const dispatch = useDispatch();
  const navigateBack = useNavigate();

  const { loading, newcollection, error } = useSelector(state => state.getNewcollection);

  useEffect(() => {
    dispatch({ type: 'newcollection_create_reset'});
    dispatch(getNewcollectionAction());
  }, [dispatch]);
  
  return (
    <div className='px-5 py-2'>
      <div className='mb-3 ms-3'>
        <button type="button" onClick={() => navigateBack(-1)} title='Go Back' style={{ left: '2rem' }}
          className="btn btn-sm btn-outline-success bg-gradient position-absolute">
          <i className="bi bi-arrow-left" /> Back
        </button>
      </div>
      <Filter />
      {
        newcollection && (
          <div>
            <h1 className={`text-center mb-4 h2 catname ${(newcollection.length === 0 && !loading) ? 'text-warning' : 'fst-italic text-light'}`}>
              {(newcollection.length === 0 && !loading) ? 'There stilll no items herre' : 'New Collection'}
            </h1>
            {
              loading ? <Loader /> : (
                error ? <Message variant={'dnager'}>{error}</Message> : (
                  <div className='row row-cols-1 row-cols-md-2 row-cols-lg-4 g-lg-3 g-md-2 justify-content-center'>
                    {
                      newcollection.map((i) => {
                        return (
                          <div className='col catblock' key={i._id}>
                            <Item item={i} subcat={true} />
                          </div>
                        )
                      })
                    }
                  </div>
                )
              )
            }
          </div>
        )
      }
    </div>
  )
}

// export default NewCollectionScreen;
export default React.memo(NewCollectionScreen);