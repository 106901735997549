import React from 'react';
import { Link } from 'react-router-dom';

function Rating(props) {
  return (
    <div>
      <p className={props.itemid ? 'card-text rating mb-2 text-dark' : 'card-text rating mb-2'}>
        Rating: {props.rating}
        {
          props.itemid ? (
            <Link className='ms-2 text-warning' title='Write Review'
              to={`/${props.catslug}/${props.subcatslug}/${props.itemid}#anchor`}>
              <span>
                <i className={
                  props.rating >= 1 ? 'bi bi-star-fill' : props.rating >= 0.5 ? 'bi bi-star-half' : 'bi bi-star'
                } />
              </span>
              <span>
                <i className={
                  props.rating >= 2 ? 'bi bi-star-fill' : props.rating >= 1.5 ? 'bi bi-star-half' : 'bi bi-star'
                } />
              </span>
              <span>
                <i className={
                  props.rating >= 3 ? 'bi bi-star-fill' : props.rating >= 2.5 ? 'bi bi-star-half' : 'bi bi-star'
                } />
              </span>
              <span>
                <i className={
                  props.rating >= 4 ? 'bi bi-star-fill' : props.rating >= 3.5 ? 'bi bi-star-half' : 'bi bi-star'
                } />
              </span>
              <span>
                <i className={
                  props.rating >= 5 ? 'bi bi-star-fill' : props.rating >= 4.5 ? 'bi bi-star-half' : 'bi bi-star'
                } />
              </span>
            </Link>
          ): (
            <Link className='ms-2 text-warning' title='Write Review' to={`#anchor`}>
              <span>
                <i className={
                  props.rating >= 1 ? 'bi bi-star-fill' : props.rating >= 0.5 ? 'bi bi-star-half' : 'bi bi-star'
                } />
              </span>
              <span>
                <i className={
                  props.rating >= 2 ? 'bi bi-star-fill' : props.rating >= 1.5 ? 'bi bi-star-half' : 'bi bi-star'
                } />
              </span>
              <span>
                <i className={
                  props.rating >= 3 ? 'bi bi-star-fill' : props.rating >= 2.5 ? 'bi bi-star-half' : 'bi bi-star'
                } />
              </span>
              <span>
                <i className={
                  props.rating >= 4 ? 'bi bi-star-fill' : props.rating >= 3.5 ? 'bi bi-star-half' : 'bi bi-star'
                } />
              </span>
              <span>
                <i className={
                  props.rating >= 5 ? 'bi bi-star-fill' : props.rating >= 4.5 ? 'bi bi-star-half' : 'bi bi-star'
                } />
              </span>
            </Link>
          )
        }
      </p>
    </div>
  )
}

export default Rating;