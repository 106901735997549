import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';

import { getAllCategoryByNameAction } from '../actions/categoryActions';
import Item from '../components/Item';
import LoadImage from '../components/LoadImage';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Filter from '../components/Filter';

function CategoryScreen() {
  
  const { catname } = useParams();
  const dispatch = useDispatch();
  const navigateBack = useNavigate();

  const { loading, list, error } = useSelector(state => state.getAllCategoryByName);
  const { subcats, items } = Object.keys(list).length > 0 && list;
  
  useEffect(() => {
    dispatch(getAllCategoryByNameAction(catname));
  }, [dispatch, catname])
  
  return (
    <div className='p-3'>
      <div className={items && (items[0] ? 'mb-3 ms-3' : 'mb-5 ms-5')} style={{width:'fit-content'}}>
        <button type="button" onClick={() => navigateBack(-1)} title='Go Back' style={{ left: '2rem' }}
          className="btn btn-sm btn-outline-success bg-gradient position-absolute">
          <i className="bi bi-arrow-left" /> Back
        </button>
      </div>
      <Filter />
      <h1 className='text-center mb-2 h2 text-light fst-italic catname'>{items && (items[0] && items[0].category.title)}</h1>
      <div className="row justify-content-center">
        {subcats && subcats.map(s => (
          <div className="col-sm-3 col-md-2 col-xl-1 text-center my-4" key={s._id}>
            <Link to={s.slug} className="flip-container">
              <div className="cardflip">
                <div className="frontflip">
                  <LoadImage
                    src={items && (items[0] ? `/static${items[0].category.image}` : '/static/images/logo.svg')}
                    height={65} className='rounded-pill' />
                  {/* <img src={items && (items[0] && `/static${items[0].category.image}`)} height={65} className='rounded-pill' /> */}
                </div>
                <div className="backflip">
                  <LoadImage src={`/static${s.image}`} height={65} className='rounded-pill' />
                  {/* <img src={`/static${s.image}`} height={65} className='rounded-pill' /> */}
                </div>
              </div>
              <h5>{s.title}</h5>
            </Link>
          </div>
        ))}
      </div>
      <div className='mb-4 mt-3 text-center'>
        <h3>Most popular of Category</h3>
      </div>
      {
        loading ? <Loader /> : (
          error ? <Message variant={'danger'}>{error}</Message> : (
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-4 g-lg-3 g-md-2 justify-content-center'>
              {items && (items.map(i => (
                <div className='col catblock' key={i._id}>
                  <Item item={i} />
                </div>
              )))}
            </div>
          )
        )
      }
    </div>
  )
}

export default React.memo(CategoryScreen);