import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getUserByIdDetailsAction, updateUserAction } from '../actions/userActions';
import Message from '../components/Message';
import Loader from '../components/Loader';

function UserEditScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const ref = useRef();
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isadmin, setIsadmin] = useState(false);
  
  const { loading, user, error } = useSelector(state => state.userByIdDetails);
  const { loading: loadingUpdate, success, error: errorUpdate } = useSelector(state => state.updateUser);
  
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateUserAction({ _id: user._id, name, email, isadmin }));
  }
  
  useEffect(() => {
    if (success) {
      dispatch({ type: 'user_update_reset' });
      navigate('/rootuser/userlist');
    } else {
      if (!user.name || user._id !== Number(id)) {
        dispatch(getUserByIdDetailsAction(id))
      } else {
        setName(user.name)
        setEmail(user.email)
        setIsadmin(user.isadmin)
      }
    }
  }, [dispatch, id, user.name, user._id, user.email, user.isadmin, navigate, success])
  
  return (
    <div>
      <div style={{ width: 'fit-content' }}>
        <button type="button" onClick={() => navigate(-1)} title='Go Back' style={{ left: '3rem' }}
          className="btn btn-sm btn-outline-success bg-gradient position-absolute">
          <i className="bi bi-arrow-left" /> Back
        </button>
      </div>
      <h1 className='text-center my-2 h2 fst-italic text-light'>Admin Edit User</h1>
      {loadingUpdate && <Loader />}
      {errorUpdate && <Message variant={'danger'}>{errorUpdate.message}</Message>}
      {
        loading ? <Loader /> : (
          <div className='mx-auto' style={{ width: '30%' }}>
            <form onSubmit={submitHandler} ref={ref}>
              <fieldset>
                <div className="form-group">
                  <label htmlFor="inputName" className="form-label mt-4 text-muted">Name</label>
                  <input type="name" className="form-control text-secondary"
                    style={{ fontSize: '0.95rem' }}
                    value={name} onChange={(e) => setName(e.target.value)}
                    id="inputName" placeholder="Name" />
                </div>
                <div className="form-group">
                  <label htmlFor="inputEmail" className="form-label mt-4 text-muted">Enter Email Address</label>
                  <input type="email" className="form-control text-secondary" id="inputEmail"
                    style={{ fontSize: '0.95rem' }}
                    value={email} onChange={(e) => setEmail(e.target.value.trim())}
                    placeholder="Enter email" />
                </div>
                <div className="form-check mt-4 ms-2">
                  <input className="form-check-input rangeinput fs-5" type="checkbox"
                    checked={isadmin} id="checkIsAdmin"
                    onChange={e => setIsadmin(e.target.checked)} />
                  <label className="form-check-label fs-6 mt-1" htmlFor="checkIsAdmin">
                    Is Admin
                  </label>
                </div>
              </fieldset>
              <button type="submit" className="btn btn-info bg-gradient mt-4 d-block mx-auto">
                Submit
              </button>
            </form>
          </div>
        )
      }
      {error && <Message variant={'danger'}>{error.message}</Message>}
    </div>
  )
}

export default UserEditScreen;