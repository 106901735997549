import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CheckoutSteps from '../components/CheckoutSteps';
import { saveShippingAddress } from '../actions/cartActions';

function ShippingScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { shippingAddress } = useSelector(state => state.cart);
  
  const [address, setAddress] = useState(shippingAddress.address)
  const [city, setCity] = useState(shippingAddress.city)
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
  const [country, setCountry] = useState(shippingAddress.country)
  
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShippingAddress({ address, city, postalCode, country }));
    navigate('/payment');
  }

  return (
    <div>
      <CheckoutSteps step1 step2 />
      <h1 className='text-center my-2 h2'>Shipping</h1>
      <div className='mx-auto loginblock' style={{ width: '30%' }}>
        <form onSubmit={submitHandler}>
          <fieldset>
            <div className="form-group">
              <label htmlFor="address" className="form-label mt-4 text-muted">Address</label>
              <input type="text" className="form-control text-secondary" id="address"
                style={{ fontSize: '0.95rem' }}
                value={address ? address : ''} onChange={(e) => setAddress(e.target.value)}
                placeholder="Address" />
            </div>
            <div className="form-group">
              <label htmlFor="city" className="form-label mt-4 text-muted">City</label>
              <input type="text" className="form-control text-secondary" id="city"
                style={{ fontSize: '0.95rem' }}
                value={city ? city : ''} onChange={(e) => setCity(e.target.value)}
                placeholder="City" />
            </div>
            <div className="form-group">
              <label htmlFor="postalCode" className="form-label mt-4 text-muted">Postal Code</label>
              <input type="text" className="form-control text-secondary"
                style={{ fontSize: '0.95rem' }}
                value={postalCode ? postalCode : ''} onChange={(e) => setPostalCode(e.target.value)}
                id="postalCode" placeholder="Postal Code" />
            </div>
            <div className="form-group">
              <label htmlFor="country" className="form-label mt-4 text-muted">Country</label>
              <input type="text" className="form-control text-secondary"
                style={{ fontSize: '0.95rem' }}
                value={country ? country : ''} onChange={(e) => setCountry(e.target.value)}
                id="country" placeholder="Country" />
            </div>
          </fieldset>
          <button type="submit" className="btn btn-info bg-gradient mt-4 d-block mx-auto">
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

export default ShippingScreen;