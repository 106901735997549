import React, {useState, useEffect} from 'react';

function Message(props) {
  const [child, setChild] = useState(props.children);
  const variant = `alert-${props.variant ? props.variant : 'warning'}`;

  /* const renderElAlert = () => {
    return React.cloneElement({ props }).props.children;
  }; */
  
  useEffect(() => {
    setChild(props.children);
  }, [props.children])
  // console.log(props.children);
  return (
    <div className='d-block mx-auto mt-4' style={{ width: 'fit-content' }}>
      {
        (child && child.length > 0) && (
          <div className={`alert ${variant} alert-dismissible text-center px-5`} role="alert">
            {
              variant === 'alert-danger' && (
                <i className="bi bi-exclamation-octagon me-3 fw-bold fs-4" style={{ height: 'fit-content' }} />
              )
            }
            <p className='mt-2'>{child}</p>
            {
              variant === 'alert-danger' && (
                <button type="button" className="btn-close" onClick={() => setChild('')} aria-label="Close"></button>
              )
            }
          </div>
        )
      }
    </div>
  )
}

export default Message;