import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { deleteSubCategoryAction } from '../actions/categoryActions';
import LoadImage from '../components/LoadImage';
import Message from '../components/Message';
import Loader from '../components/Loader';

function SubCategoryListScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const [catname] = useState(location.state.catname);
  const [subcats] = useState(location.state.subcats);
  
  const { userInfo } = useSelector(state => state.loginUser);
  
  const { loading, response, error } = useSelector(state => state.deleteSubCategory);
  
  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      dispatch(deleteSubCategoryAction(id));
    }
  }
  
  useEffect(() => {
    !userInfo.isadmin && navigate('/');
    if (response) {
      dispatch({ type: 'delete_subcategory_reset' });
      navigate('/rootuser/categorylist');
    }
  }, [dispatch, navigate, userInfo.isadmin, location.state, subcats, catname, response]);
  return (
    <div>
      <div style={{ width: 'fit-content' }}>
        <button type="button" onClick={() => navigate(-1)} title='Go Back' style={{ left: '3rem' }}
          className="btn btn-sm btn-outline-success bg-gradient position-absolute">
          <i className="bi bi-arrow-left" /> Back
        </button>
      </div>
      <div style={{ width: 'fit-content' }}>
        <button type="button" onClick={() => navigate('/rootuser/subcategorylist/0', {state: {'catname': catname}})}
          title='Create New SubCategory' style={{ right: '8rem' }}
          className="btn btn-sm btn-outline-info bg-gradient position-absolute">
          Create New SubCategory
        </button>
      </div>
      <h1 className='text-center my-2 h2 fst-italic text-light'>
        Admin List SubCategories of <span className='text-primary'>{catname}</span>
      </h1>
      <div className="col border-secondary container mt-3">
        <div className="table-responsive">
          {loading && <Loader />}
          {error && <Message variant={'danger'}>{error.message}</Message>}
          <table className="table table-striped table-bordered">
            <thead>
              <tr className='text-success text-center'>
                <th scope="col">ID</th>
                <th scope="col">Title</th>
                <th scope="col">Meta Description</th>
                <th scope="col">Slug</th>
                <th scope="col">Image</th>
                <th scope="col">Created</th>
              </tr>
            </thead>
            <tbody>
              {
                subcats.map((sub) => (
                  <tr key={sub._id}>
                    <td>{sub._id}</td>
                    <td>{sub.title}</td>
                    <td>{sub.metadesc}</td>
                    <td>{sub.slug}</td>
                    <td>
                      <LoadImage src={`/static${sub.image}`} height={40} /> 
                    </td>
                    <td>{String(sub.created).substring(0, 10)}</td>
                    <td className='col-2 text-center pt-3'>
                      
                      <Link to={`/rootuser/subcategorylist/${sub._id}`} title='Edit SubCategory' className='me-3'
                        state={{
                          title: sub.title,
                          metadesc: sub.metadesc,
                          slug: sub.slug,
                          img: sub.image
                        }}>
                        <button type="button"
                          style={{ padding: '0.2rem', fontSize: '0.9em' }}
                          className="btn btn-sm btn-outline-success bg-gradient">
                          <i className="bi bi-pencil me-1" />
                          Edit
                        </button>
                      </Link>
                      <button type="button" title='Delete SubCategory'
                        onClick={() => deleteHandler(sub._id)}
                        onMouseUp={(e) => e.target.blur()}
                        style={{ padding: '0.1rem 0.2rem 0.2rem 0.2rem', fontSize: '0.9em' }}
                        className="btn btn-sm btn-outline-danger bg-gradient">
                        <i className="bi bi-trash3 me-1" />
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default SubCategoryListScreen