import axios from "axios";
import { createAction } from "@reduxjs/toolkit";

export const getBrandsAction = () => createAction(null, async (dispatch) => {
  try {
    dispatch({ type: 'get_brands_request' });
    
    const config = {
      headers: {
        'Content-type': 'application/json'
      }
    };
    
    const { data } = await axios.get(`/api/brands/`, config);
    
    dispatch({
      type: 'get_brands_success',
      payload: data
    });
    
    sessionStorage.setItem('brands', JSON.stringify(data.brands));
  
  } catch (e) {
    dispatch({
      type: 'get_brands_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

export const brandItemsAction = (brand) => createAction(null, async (dispatch) => {
  try {
    dispatch({ type: 'brand_items_request' });
    
    const config = {
      headers: {
        'Content-type': 'application/json'
      }
    };
    
    const { data } = await axios.get(`/api/brands/${brand}/items/`, config);
    
    dispatch({
      type: 'brand_items_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'brand_items_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

// Update Brand for admin panel
export const updateBrandAction = (brand) => createAction(null, async (dispatch, getState) => {
  
  try {
    dispatch({ type: 'update_brand_request' });
    
    const { loginUser: { userInfo } } = getState();
    const config = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.patch(`/api/brands/update/${brand._id}/`, brand, config);
    
    dispatch({
      type: 'update_brand_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'update_brand_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

// Create Brand for admin panel
export const createBrandAction = (brand) => createAction(null, async (dispatch, getState) => {
  try {
    dispatch({ type: 'create_brand_request' });
    
    const { loginUser: { userInfo } } = getState();
    const config = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.post(`/api/brands/create/`, brand, config);
    
    dispatch({
      type: 'create_brand_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'create_brand_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

// Delete Brand for admin panel
export const deleteBrandAction = (id) => createAction(null, async (dispatch, getState) => {
  
  try {
    dispatch({ type: 'delete_brand_request' });
    
    const { loginUser: { userInfo } } = getState();
    const config = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${userInfo.token}`
      }
    };

    const { data } = await axios.delete(`/api/brands/delete/${id}/`, config);

    dispatch({
      type: 'delete_brand_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'delete_brand_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});