import { createSlice } from "@reduxjs/toolkit";
import { 
  loginUser, logoutuserAction, registerUserAction, userListAction, getUserByIdDetailsAction,
  updateUserAction, deleteUserAction
 } from "../actions/userActions";

export const userLoginSlice = createSlice({
  name: 'userLogin',
  initialState: {userInfo:{}},
  reducers: {
    loginUserProfile: (state, action) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.userInfo = action.payload;
        state.loading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase(logoutuserAction, () => {
        return {}
      })
      .addDefaultCase((state, action) => {
        state.error = {}
      })
  }
})

export const userRegisterSlice = createSlice({
  name: 'userRegister',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerUserAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUserAction.fulfilled, (state, action) => {
        state.userInfo = action.payload;
        state.loading = false;
      })
      .addCase(registerUserAction.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase(logoutuserAction, () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
})

export const getUserDetailsSlice = createSlice({
  name: 'userDetails',
  initialState: { user: {} },
  extraReducers: (builder) => {
    builder
      .addCase('user_details_request', (state) => {
        state.loading = true;
      })
      .addCase('user_details_success', (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase('user_details_failure', (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const updateUserProfileSlice = createSlice({
  name: 'updateUserProfile',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('user_updateprofile_success', (state, action) => {
        state.success = true;
        state.userInfo = action.payload;
      })
      .addCase('user_updateprofile_failure', (state, action) => {
        state.error = action.payload;
      })
      .addCase('reset_profile', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const userListSlice = createSlice({
  name: 'userList',
  initialState: { users: [] },
  extraReducers: (builder) => {
    builder
      .addCase(userListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(userListAction.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(userListAction.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase('user_list_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

// Get user profile by ID for admin panel
export const getUserByIdDetailsSlice = createSlice({
  name: 'getUserByIdDetails',
  initialState: { user: {} },
  extraReducers: (builder) => {
    builder
      .addCase(getUserByIdDetailsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserByIdDetailsAction.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(getUserByIdDetailsAction.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase('userby_id_reset', () => {
        return { user: {} }
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

// Update user profile by ID for admin panel
export const updateUserSlice = createSlice({
  name: 'updateUser',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUserAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserAction.fulfilled, (state, action) => {
        state.success = true;
        state.loading = false;
      })
      .addCase(updateUserAction.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase('user_update_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const deleteUserSlice = createSlice({
  name: 'deleteUser',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteUserAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUserAction.fulfilled, (state, action) => {
        state.successDelete = action.payload;
        state.loading = false;
      })
      .addCase(deleteUserAction.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase('user_delete_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});