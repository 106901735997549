import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { updateSubCategoryAction, createSubCategoryAction } from '../actions/categoryActions';
import Message from '../components/Message';
import Loader from '../components/Loader';
import LoadImage from '../components/LoadImage';

function SubCategoryEditScreen() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const [title, setTitle] = useState('');
  const [catname] = useState(location.state.catname);
  const [image, setImage] = useState('');
  const [uploadimage, setUploadimage] = useState('');
  const [metadesc, setMetadesc] = useState('');
  const [slug, setSlug] = useState('');
  const [uploading, setUploading] = useState(false);

  const { loading, success, error } = useSelector(state => state.updateSubCategory);
  const {
    loading: loadingCreate, successCreate, error: errorCreate
  } = useSelector(state => state.createSubCategory);
  
  const submitHandler = (e) => {
    e.preventDefault();
    if (Number(id) !== 0) {
      dispatch(updateSubCategoryAction({
        _id: id, title, metadesc, slug, image
      }));
    } else {
      dispatch(createSubCategoryAction({
        category: catname, title, metadesc, slug, image
      }));
    }
  };
  
  const uploadFileHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append('image', file);
    formData.append('id', id);
    Number(id) === 0 && formData.append('prefix', 'subcatimg');
    
    setUploading(true);
    
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    
    await axios.post(
      '/api/categories/subcatupload/', formData, config
    ).then(res => {
      setUploadimage(res.data.response);
      setImage(res.data.image);
    }).catch(err => {
      setUploadimage(err);
    })
    setUploading(false);
  }

  useEffect(() => {
    if (Number(id) !== 0) {
      const statevals = location.state;
      setTitle(statevals.title);
      setMetadesc(statevals.metadesc);
      setSlug(statevals.slug);
      setImage(statevals.img);
    }
    if (success || successCreate) {
      successCreate && dispatch({ type: 'create_subcategory_reset' });
      success && dispatch({ type: 'update_subcategory_reset' });
      navigate('/rootuser/categorylist');
    }
  }, [dispatch, id, location, navigate, success, successCreate]);

  return (
    <div>
      <div style={{ width: 'fit-content' }}>
        <button type="button" onClick={() => navigate(-1)} title='Go Back' style={{ left: '3rem' }}
          className="btn btn-sm btn-outline-success bg-gradient position-absolute">
          <i className="bi bi-arrow-left" /> Back
        </button>
      </div>
      <h1 className='text-center my-2 h2 fst-italic text-light'>Admin {Number(id) !== 0 ? 'Edit' : 'Create'} Category</h1>
      <div className='col-8 col-xxl-5 mx-auto'>
        {loadingCreate && <Loader />}
        {errorCreate && <Message variant={'danger'}>{errorCreate.message}</Message>}
        {loading && <Loader />}
        {error && <Message variant={'danger'}>{error.message}</Message>}
        <form onSubmit={submitHandler}>
          <fieldset>
            <div className="form-group edititeminpt">
              <label htmlFor="title" className="form-label mt-4 text-muted">Title</label>
              <input type="name" className="form-control text-secondary" id="title"
                value={title} onChange={(e) => setTitle(e.target.value.trimEnd())}
                placeholder="Category Title" />
            </div>
            <div className="form-group edititeminpt">
              <label htmlFor="metadesc" className="form-label mt-4 text-muted">Meta Description</label>
              <input type="text" className="form-control text-secondary" id="metadesc"
                value={metadesc} onChange={(e) => setMetadesc(e.target.value)}
                placeholder="Meta Description" />
            </div>
            <div className="form-group edititeminpt">
              <label htmlFor="slug" className="form-label mt-4 text-muted">Slug</label>
              <input type="text" className="form-control text-secondary" id="slug"
                value={slug} onChange={(e) => setSlug(e.target.value.trim())}
                placeholder="Slug" />
            </div>
            <div className="form-group edititeminpt col-5">
              <label htmlFor="formFile" className="form-label mt-4 text-muted">
                {
                  uploading ? <Loader /> : (
                    uploadimage ? uploadimage : 'Upload Image'
                  )
                }
                {
                  image ? (
                    <span className='ms-2 float-end' style={{ cursor: 'pointer' }}>
                      <LoadImage src={`/static${image}`} height={40} className='me-2' />
                      {image}
                    </span>
                  ) : (
                    <span className='ms-2 float-end' style={{ cursor: 'pointer' }}>
                      <LoadImage src='/static/images/placeholder.png' height={40} />
                    </span>
                  )
                }
              </label>
              <input className="form-control text-secondary"
                type="file" id="formFile" onChange={uploadFileHandler} />
            </div>
          </fieldset>
          <button type="submit" className="btn btn-info bg-gradient mt-4 d-block mx-auto">
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

export default SubCategoryEditScreen;