import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CheckoutSteps from '../components/CheckoutSteps';
import { savePaymentMethod } from '../actions/cartActions';

function PaymentScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState('')
  
  const { shippingAddress } = useSelector(state => state.cart);
  
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    navigate('/placeorder');
  }
  
  useEffect(() => {
    !shippingAddress.address && navigate('/shipping');
  })
  
  return (
    <div>
      <CheckoutSteps step1 step2 step3 />
      <h1 className='text-center my-2 h2'>Payment</h1>
      <div className='mx-auto justify-content-center d-flex px-3'>
        <form onSubmit={submitHandler}>
          <fieldset>
            <div className="my-3">
              <div className="form-check my-4">
                <input className="form-check-input fs-5" type="radio" name="paymethod" id="paymethod" defaultChecked />
                <label className="form-check-label fs-5" htmlFor="paymethod">
                  Here are Payments Methods
                </label>
              </div>

              <div className="form-check my-2">
                <input id="credit" name="paymentMethod" type="radio" className="form-check-input fs-5 rangeinput" disabled/>
                <label className="form-check-label fs-5" htmlFor="credit">Credit card</label>
              </div>
              <div className="form-check my-2">
                <input id="debit" name="paymentMethod" type="radio" className="form-check-input fs-5 rangeinput" disabled />
                <label className="form-check-label fs-5" htmlFor="debit">Debit card</label>
              </div>
              <div className="form-check my-2">
                <input id="paypal" name="paymentMethod" type="radio" className="form-check-input fs-5 rangeinput" disabled />
                <label className="form-check-label fs-5" htmlFor="paypal">PayPal</label>
              </div>
            </div>
          </fieldset>
          <div className="row gy-3">
            <div className="col-md-6">
              <label htmlFor="cc-name" className="form-label">Name on card</label>
              <input type="text" className="form-control" id="cc-name" disabled/>
                <small className="text-muted">Full name as displayed on card</small>
                <div className="invalid-feedback">
                  Name on card is required
                </div>
            </div>

            <div className="col-md-6">
              <label htmlFor="cc-number" className="form-label">Credit card number</label>
              <input type="text" className="form-control" id="cc-number" disabled/>
                <div className="invalid-feedback">
                  Credit card number is required
                </div>
            </div>

            <div className="col-md-3">
              <label htmlFor="cc-expiration" className="form-label">Expiration</label>
              <input type="text" className="form-control" id="cc-expiration" disabled/>
                <div className="invalid-feedback">
                  Expiration date required
                </div>
            </div>

            <div className="col-md-3">
              <label htmlFor="cc-cvv" className="form-label">CVV</label>
              <input type="text" className="form-control" id="cc-cvv" disabled/>
                <div className="invalid-feedback">
                  Security code required
                </div>
            </div>
          </div>
          <button type="submit" className="btn btn-info bg-gradient mt-4 d-block mx-auto"
            onClick={() => setPaymentMethod('Here are Payments Methods')}>
            Continue
          </button>
        </form>
      </div>
    </div>
  )
}

export default PaymentScreen