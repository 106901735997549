import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='bg-dark bg-gradient container-fluid mt-5'>
      <div className='container'>
        <footer className="py-3 my-4">
          <ul className="nav justify-content-center border-bottom pb-3 mb-3">
            <li className="nav-item">
              <Link to={'#'} className="nav-link px-2 text-light footerHoveredText">Home</Link>
            </li>
            <li className="nav-item">
              <Link to={'#'} className="nav-link px-2 text-light footerHoveredText">Features</Link>
            </li>
            <li className="nav-item">
              <Link to={'#'} className="nav-link px-2 text-light footerHoveredText">Pricing</Link>
            </li>
            <li className="nav-item">
              <Link to={'#'} className="nav-link px-2 text-light footerHoveredText">FAQ</Link>
            </li>
            <li className="nav-item">
              <Link to={'#'} className="nav-link px-2 text-light footerHoveredText">About</Link>
            </li>
          </ul>
          <p className="text-center text-light">© 2022 Company, Inc</p>
        </footer>
      </div>
    </div>
  )
}

export default Footer