import React from 'react';
import { Link } from 'react-router-dom';

function CheckoutSteps(props) {
  
  return (
    <div>
      <div className='justify-content-center my-3 nav h5 checkoutsteps'>
        <div className='nav-item me-4'>
          {
            props.step1 ? (
              <Link to={'/login'}>
                <i className="bi bi-file-earmark-person" />
                Login
              </Link>
            ): (
              <div className='text-secondary'>
                  <i className="bi bi-file-earmark-person" />
                  Login
              </div> 
            )
          }
        </div>
        <div className='nav-item me-4'>
          {
            props.step2 ? (
              <Link to={'/shipping'}>
                <i className="bi bi-truck" />
                Shipping
              </Link>
            ): (
              <div className='text-secondary'>
                <i className="bi bi-truck" />
                Shipping
              </div>
            )
          }
        </div>
        <div className='nav-item me-4'>
          {
            props.step3 ? (
              <Link to={'/payment'}>
                <i className="bi bi-credit-card-2-front" />
                Payment
              </Link>
            ) : (
              <div className='text-secondary'>
                <i className="bi bi-credit-card-2-front" />
                Payment
              </div>
            )
          }
        </div>
        <div className='nav-item'>
          {
            props.step4 ? (
              <Link to={'/placeorder'}>
                <i className="bi bi-bag-check" />
                Place Order
              </Link>
            ) : (
              <div className='text-secondary'>
                <i className="bi bi-bag-check" />
                Place Order
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default React.memo(CheckoutSteps);