import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { listCategories } from '../actions/categoryActions';
import { logoutuserAction } from '../actions/userActions';
import LoadImage from './LoadImage';

function Header() {
  let ref = useRef();
  const [dropdown, setDropdown] = useState(false);
  const [dropsubmenu, setDropsubmenu] = useState(false);
  const [cats, setCats] = useState();
  const [subcat, setSubcat] = useState({ catSlug: '', subcategory: [] });
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.loginUser.userInfo);
  const navigate = useNavigate();
  
  // presubmenuRight: for calculate place on along the X guide to open `.submenu`
  const [presubmenuRight, setPresubmenuright] = useState();

  // get screen postion of image event by x-axis `x` & current width image
  const getXaxisSubmenu = (e) => {
    let getimg = Number;
    if (e.target.id === 'subitem') {
      getimg = e.target.children[0].children[0];
    } else if (e.target.localName === 'span') {
      getimg = e.target.children[0];
    } else if (e.target.localName === 'img') {
      getimg = e.target;
    }
    getimg && setPresubmenuright(`${getimg.offsetLeft + getimg.clientWidth * 1.25}px`);
  }
  
  const onMouseEnter = (e) => {
    e.preventDefault();
    if (window.innerWidth > 960) {
      if (!e.target.id) {
        setDropdown(true)
      } else {
        setDropdown(true)
        setDropsubmenu(true)
      }
    }
    getXaxisSubmenu(e);
  }
  
  const onMouseLeave = (e) => {
    e.preventDefault();
    if (window.innerWidth > 960) {
      if (!e.target.id) {
        setDropdown(false)
      } else if (e.target.id === 'divmenu' && e.target.id !== 'subitem') {
        setDropsubmenu(false)
        setDropdown(false)
      } else {
        setDropsubmenu(false)
        setDropdown(false)
      }
    }
  }
  
  const logoutHandler = () => {
    dispatch(logoutuserAction());
    setTimeout(() => {
      navigate('/')
    }, 100);
  }
  
  const addCats = useCallback(() => {
    dispatch(listCategories()).then(r => {
      return setCats(r.payload)
    })
  }, [dispatch])
  
  useEffect(() => {
    addCats();
    
    const menutimerpointer = (e) => setTimeout(() => {
      e.target.offsetParent.style = 'pointer-events: initial';
    }, 200);
    
    const handler = (e) => setTimeout(() => {
      if (ref.current && ref.current.contains(e.target)) {
        e.target.offsetParent.style = 'pointer-events: none';
        setDropsubmenu(false);
        menutimerpointer(e);
      }
    }, 300);
    
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    
    return () => {
      clearTimeout(handler);
      clearTimeout(menutimerpointer);
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
    
  }, [addCats])
  
  // Calculated height to open `.submenu`
  const heightSubmenu = window.innerWidth > 960
    ? subcat.subcategory && subcat.subcategory.length * 2 + 'rem' : 0;
  
  return (
    <div>
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
          <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarColor01">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item mx-4">
                  <NavLink className={
                    ({ isActive }) => isActive
                      ? 'nav-link text-center d-grid py-0 text-dark'
                      : 'nav-link text-center d-grid py-0'} to={'/'} title="Home">
                    <i className="bi bi-house-door fs-4" style={{ marginBottom: '-0.4rem' }} />
                    <span className="fw-bolder" style={{ fontSize: '0.65em' }}>Home</span>
                  </NavLink>
                </li>
                <li className="nav-item mx-4">
                  <Link role="button"
                    onClick={() => setDropdown((prev) => !prev)}
                    onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
                    className={`nav-link text-center d-grid py-0 ${dropdown && 'text-dark'}`}
                    to={'#'} title="Categories">
                    <i className="bi bi-menu-up fs-4" style={{ marginBottom: '-0.4rem' }} />
                    <span className="fw-bolder" style={{ fontSize: '0.65em' }}>Categories</span>
                  </Link>
                  <div className={`rootcats precats ${dropdown && 'slidecats'}`}
                    onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
                    <div className='cats' ref={ref}>
                      {
                        cats && cats.map(c => (
                          <Link className="dropdown-item" to={c.slug} id='subitem'
                            key={c._id} onMouseOver={() => {
                              window.innerWidth > 960
                                && setSubcat({ catSlug: c.slug, subcategory: c.subcategory })
                            }}
                            onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            {c.title}
                            <span onMouseEnter={() => setDropsubmenu(true)}>
                              <LoadImage src={`/static${c.image}`} />
                              {/* <img src={`static${c.image}`} alt='' /> */}
                            </span>
                          </Link>
                        ))
                      }
                    </div>
                    <div className={`rootcats presubmenu ${(dropsubmenu && window.innerWidth > 960) && 'submenu'}`}
                      style={{
                        left: presubmenuRight ?? presubmenuRight,
                        height: dropsubmenu ? heightSubmenu : 0
                      }}
                      onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} id='divmenu'>
                      {
                        subcat.subcategory && subcat.subcategory.map(s => (
                          <Link key={s._id} className="dropdown-item" id='divmenu'
                            onClick={() => setDropsubmenu((prev) => !prev)}
                            to={`${subcat.catSlug}/${s.slug}`}>
                            {s.title}
                          </Link>
                        ))
                      }
                    </div>
                  </div>
                </li>
                <li className="nav-item mx-4">
                  <NavLink className={
                    ({ isActive }) => isActive
                      ? 'nav-link text-center d-grid py-0 text-dark'
                      : 'nav-link text-center d-grid py-0'} to={'cart'} title="Cart">
                    <i className="bi bi-cart3 fs-4" style={{ marginBottom: '-0.4rem' }} />
                    <span className="fw-bolder" style={{ fontSize: '0.65em' }}>Cart</span>
                  </NavLink>
                </li>
                <li className="nav-item dropdown mx-4">
                  {
                    userInfo ? (
                      userInfo.isadmin ? (
                        <div>
                          <Link className="nav-link dropdown-toggle text-center d-grid py-0"
                            data-bs-toggle="dropdown" to={'#'} role="button"
                            aria-haspopup="true" aria-expanded="false" title="Admin">
                            <i className="bi bi-person fs-4" style={{ marginBottom: '-0.4rem' }} />
                            <span className="fw-bolder" style={{ fontSize: '0.65em' }}>Admin { userInfo.name }</span>
                          </Link>
                          <div className="dropdown-menu" title='Admin'>
                            <Link className="dropdown-item" to={"/rootuser/userlist"}>
                              <i className="bi bi-people me-2 fs-6"/>
                              Users
                            </Link>
                            <Link className="dropdown-item" to={"/rootuser/itemlist"}>
                              <i className="bi bi-card-checklist me-2 fs-6" />
                              Items
                            </Link>
                            <Link className="dropdown-item" to={"/rootuser/orderlist"}>
                              <i className="bi bi-person-lines-fill me-2 fs-6" />
                              Orders
                            </Link>
                            <Link className="dropdown-item" to={"/rootuser/brandlist"}>
                              <i className="bi bi-bookmark-star me-2 fs-6" />
                              Brands
                            </Link>
                            <Link className="dropdown-item" to={"/rootuser/categorylist"}>
                              <i className="bi bi-file-earmark-medical me-2 fs-6" />
                              Categories
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link className="dropdown-item" to={"profile"}>
                              <i className="bi bi-person-workspace me-2 fs-6" /> 
                              Profile
                            </Link>
                            <Link className="dropdown-item" onClick={logoutHandler} to={"#"}>
                              <i className="bi bi-door-closed me-2 fs-6"/>
                              Logout
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Link className={`nav-link dropdown-toggle text-center d-grid py-0`}
                              to={'#'} role="button" title={`${userInfo.name}`}
                              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="bi bi-person-check fs-4" style={{ marginBottom: '-0.4rem' }} />
                            <span className="fw-bolder me-1" style={{ fontSize: '0.65em' }}>{userInfo.name}</span>
                          </Link>
                          <div className="dropdown-menu">
                              <Link className="dropdown-item"
                                to={"profile"}>
                              <i className="bi bi-person-workspace me-2 fs-6" /> 
                              Profile / Orders
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link className="dropdown-item" onClick={logoutHandler} to={"#"}>
                              <i className="bi bi-door-closed me-2 fs-6"/>
                              Logout
                            </Link>
                          </div>
                        </div>
                      )
                    ) : (
                        <NavLink className={
                          ({ isActive }) => isActive
                            ? 'nav-link text-center d-grid py-0 text-dark'
                            : 'nav-link text-center d-grid py-0'}
                          to={'login'} role="button" aria-haspopup="true" aria-expanded="false" title="Login">
                          <i className="bi bi-person fs-4" style={{ marginBottom: '-0.4rem' }} />
                          <span className="fw-bolder" style={{ fontSize: '0.65em' }}>Login</span>
                        </NavLink>
                    )
                  }
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}

// export default Header;
export default React.memo(Header);