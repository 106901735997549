import React, { useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getOrderDetails, payOrderAction, deliverOrderAction } from '../actions/orderActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import LoadImage from '../components/LoadImage';

function OrderScreen() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { loading, order, error } = useSelector(state => state.orderDetails);
  const { loading: loadingPay, success: successPay } = useSelector(state => state.payOrder);
  const { success: resOrderDeliver, error: errOrderDeliver } = useSelector(state => state.deliverOrder);
  const { userInfo } = useSelector(state => state.loginUser);
  
  const itemsPrice = order && order.orderItems.reduce(
    (acc, item) => acc + item.price * item.qty, 0).toFixed(2);
  
  const successPaymenthandler = () => {
    dispatch(payOrderAction(id));
  }
  
  const deliverHandler = () => {
    dispatch(deliverOrderAction(order));
  }
  
  useEffect(() => {
    !userInfo && navigate('login');
    
    if (!order || successPay || order._id !== Number(id) || resOrderDeliver) {
      dispatch({ type: 'order_pay_reset' });
      dispatch({ type: 'order_deliver_reset' });
      dispatch(getOrderDetails(id));
    }
    
  }, [dispatch, id, userInfo, navigate, successPay, order, resOrderDeliver])
  
  return (
    <div>
      {
        loading ? <Loader /> : (
          error ? <Message variant={'danger'}>{error}</Message> : (
            order && (
              <div>
                <h1 className='text-center mt-2 mb-4 h2'>Order: {order._id}</h1>
                <div className='row container mx-auto'>
                  <div className="col-md-8">
                    <div className='list-group-flush'>
                      <div className='list-group-item mb-2'>
                        <div className="col my-2">
                          <h4 className='mb-4'>Shipping</h4>
                          <p>
                            <strong className='text-muted'>Name: </strong>
                            {order.user.name}
                          </p>
                          <p>
                            <strong className='text-muted'>Email: </strong>
                            {order.user.email}
                          </p>
                          <strong className='text-muted'>Shipping: </strong>
                          {order.shippingAddress.address}, {order.shippingAddress.city},
                          {order.shippingAddress.postalCode}, {order.shippingAddress.country}
                        </div>
                        {
                          order.isDelivered
                            ? (
                              <div className="alert alert-success mt-4">
                                <p className="mb-0">Delivered</p>
                              </div>
                            ) : (
                              <div className="alert alert-warning mt-4">
                                <p className="mb-0">Not Delivered!</p>
                              </div>
                            )
                        }
                      </div>
                      <div className='list-group-item mb-2'>
                        <div className="col my-2">
                          <h4 className='mb-4'>Payments Methods</h4>
                          <strong className='text-muted'>Method: </strong>
                          {order.paymentMethod}
                        </div>
                        {
                          order.isPaid
                            ? (
                              <div className="alert alert-success mt-4">
                                <p className="mb-0">Paid</p>
                              </div>
                            ) 
                            : (
                              <div className="alert alert-warning mt-4">
                                <p className="mb-0">Not Paid!</p>
                              </div> 
                            )
                        }
                      </div>
                      <div className='list-group-item my-3'>
                        <h4 className='mb-3'>Order items</h4>
                        <strong className='mb-4 d-block text-muted'>Products: </strong>
                        {
                          order.orderItems.map((item, index) => (
                            <div className='row mb-3 pb-2' style={{ borderBottom: '1px dotted var(--bs-light)' }} key={index}>
                              <div className="col-2">
                                <LoadImage src={`/static${item.image}`}
                                  className='img' style={{ maxWidth: '80%', height: 'auto' }} />
                              </div>
                              <div className="col-md-4 mt-1">
                                <Link to={`/${item.catslug}/${item.subcatslug}/${item.item}`} target="_blank"
                                  className="small text-decoration-none cat-header-link">{item.title}</Link>
                              </div>
                              <div className="col-md-2 mt-2">{item.price}$</div>
                              <div className="col-md-3 mt-2">
                                Count Items:&nbsp;
                                <strong className='text-success'>{item.qty}</strong>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="card pb-3">
                      <div className="list-group list-group-flush">
                        <div className="list-group-item text-center">
                          <h4 className="mb-4">Order Summary</h4>
                          <div className="row border-bottom border-success mt-3">
                            <div className="col">Items:</div>
                            <div className="col">{itemsPrice}$</div>
                          </div>
                          <div className="row border-bottom border-success mt-3">
                            <div className="col">Shipping price:</div>
                            <div className="col">{order.shippingPrice}$</div>
                          </div>
                          <div className="row border-bottom border-success mt-3">
                            <div className="col">Tax:</div>
                            <div className="col">{order.taxPrice}$</div>
                          </div>
                          <div className="row border-bottom border-success mt-3">
                            <div className="col">Total:</div>
                            <div className="col">{order.totalPrice}$</div>
                          </div>
                        </div>
                        {
                          !order.isPaid && (
                            loadingPay ? <Loader /> : (
                              <div className='text-center list-group-item mt-3'>
                                <button type="button" className="btn btn-info bg-gradient"
                                  onClick={successPaymenthandler}>
                                  Pay Button
                                </button>
                              </div>
                            )
                          )
                        }
                        {
                          userInfo && userInfo.isadmin && order.isPaid && !order.isDelivered && (
                            <div className='text-center list-group-item mt-3'>
                              <button type="button" className="btn btn-info bg-gradient"
                                onClick={deliverHandler}>
                                Mark As Delivered
                              </button>
                            </div>
                          )
                        }
                      </div>
                    </div>
                    {errOrderDeliver && <Message variant={'danger'}>{errOrderDeliver}</Message>}
                  </div>
                </div>
              </div>
            )
          )
        )
      }
    </div>
  )
}

export default React.memo(OrderScreen);