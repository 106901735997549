import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getBrandsAction, deleteBrandAction } from '../actions/brandActions';
import LoadImage from '../components/LoadImage';
import Message from '../components/Message';
import Loader from '../components/Loader';

function BrandListScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector(state => state.loginUser);
  const { loading, brands, error } = useSelector(state => state.getBrands);
  const {
    loading: deleteLoading, response, error: deleteErrror
  } = useSelector(state => state.deleteBrand);
  
  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      dispatch(deleteBrandAction(id));
    }
  }
  
  useEffect(() => {
    ((userInfo && userInfo.isadmin) || response) ? dispatch(getBrandsAction()) : navigate('/');
    return () => {
      response && dispatch({ type: 'delete_brand_reset' });
    }
  }, [dispatch, navigate, userInfo, response]);
  
  return (
    <div>
      <div style={{ width: 'fit-content' }}>
        <button type="button" onClick={() => navigate('/rootuser/brandlist/0')} title='Create Brand' style={{ right: '8rem' }}
          className="btn btn-sm btn-outline-info bg-gradient position-absolute">
          Create New Brand
        </button>
      </div>
      <h1 className='text-center my-2 h2 fst-italic text-light'>Admin Brands List</h1>
      <div className="col border-secondary container mt-3">
        <div className="table-responsive">
          {deleteLoading && <Loader />}
          {deleteErrror && <Message variant={'danger'}>{deleteErrror}</Message>}
          {response && <Message>{response}</Message>}
          {
            loading ? <Loader /> : (error ? <Message variant={'danger'}>{error.message}</Message> : (
              <table className="table table-striped table-bordered">
                <thead>
                  <tr className='text-success text-center'>
                    <th scope="col">ID</th>
                    <th scope="col">Title</th>
                    <th scope="col">Meta Description</th>
                    <th scope="col">Slug</th>
                    <th scope="col">Logo (image)</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    brands.map((brand) => (
                      <tr key={brand._id} className='text-center'>
                        <td>{brand._id}</td>
                        <td>{brand.title}</td>
                        <td>{brand.metadesc}</td>
                        <td>{brand.slug}</td>
                        <td><LoadImage src={`/static${brand.image}`} height={40} /></td>
                        <td className='col-2 text-center pt-3'>
                          <Link to={`/rootuser/brandlist/${brand._id}`}
                            state={{
                              title: brand.title,
                              metadesc: brand.metadesc,
                              slug: brand.slug,
                              img: brand.image
                            }}
                            title='Edit Brand' className='me-3'>
                            <button type="button"
                              style={{ padding: '0.2rem', fontSize: '0.9em' }}
                              className="btn btn-sm btn-outline-success bg-gradient">
                              <i className="bi bi-pencil me-1" />
                              Edit
                            </button>
                          </Link>
                          <button type="button" title='Delete Brand'
                            onClick={() => deleteHandler(brand._id)}
                            onMouseUp={(e) => e.target.blur()}
                            style={{ padding: '0.1rem 0.2rem 0.2rem 0.2rem', fontSize: '0.9em' }}
                            className="btn btn-sm btn-outline-danger bg-gradient">
                            <i className="bi bi-trash3 me-1" />
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default BrandListScreen;