import axios from 'axios'
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";

export const listCategories = createAsyncThunk(
  'getCategories',
  async () => {
    try {
      const { data } = await axios.get('/api/categories/');
      return data
    } catch (error) {
      throw Error(error)
    }
  }
);

export const getAllCategoryByNameAction = createAsyncThunk(
  'getAllCategoryByName',
  async (slug) => {
    try {
      const { data } = await axios.get(`/api/categories/${slug}`);
      return data
    } catch (error) {
      throw Error(error)
    }
  }
);

export const getSubcatItems = createAsyncThunk(
  'getSubcatItems',
  async (pathvals) => {
    const { catname, subcatname, keyword } = pathvals;
    try {
      const { data } = await axios.get(`/api/categories/${catname}/${subcatname}/${keyword}`);
      return data
    } catch (error) {
      throw Error(error)
    }
  }
);

export const filterItemsAction = (values) => createAction(null, async (dispatch) => {
  
  try {
    dispatch({ type: 'filter_items_request' });
    
    const config = {
      headers: {
        'Content-type': 'application/json'
      }
    };
    
    const { data } = await axios.post(`/api/categories/filter/`, values, config);
    
    dispatch({
      type: 'filter_items_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'filter_items_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

// Update Category for admin panel
export const updateCategoryAction = (cat) => createAction(null, async (dispatch, getState) => {
  
  try {
    dispatch({ type: 'update_category_request' });
    
    const { loginUser: { userInfo } } = getState();
    const config = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.patch(`/api/categories/update/${cat._id}/`, cat, config);
    
    dispatch({
      type: 'update_category_success',
      payload: data
    });

    // dispatch(listCategories.fulfilled);
  
  } catch (e) {
    dispatch({
      type: 'update_category_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

// Create Category for admin panel
export const createCategoryAction = (cat) => createAction(null, async (dispatch, getState) => {
  try {
    dispatch({ type: 'create_category_request' });
    
    const { loginUser: { userInfo } } = getState();
    const config = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.post(`/api/categories/create/`, cat, config);
    
    dispatch({
      type: 'create_category_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'create_category_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

// Delete Category for admin panel
export const deleteCategoryAction = (id) => createAction(null, async (dispatch, getState) => {

  try {
    dispatch({ type: 'delete_category_request' });

    const { loginUser: { userInfo } } = getState();
    const config = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${userInfo.token}`
      }
    };

    const { data } = await axios.delete(`/api/categories/delete/${id}/`, config);

    dispatch({
      type: 'delete_category_success',
      payload: data
    });

  } catch (e) {
    dispatch({
      type: 'delete_category_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

// Update SubCategory for admin panel
export const updateSubCategoryAction = (subcat) => createAction(null, async (dispatch, getState) => {
  
  try {
    dispatch({ type: 'update_subcategory_request' });
    
    const { loginUser: { userInfo } } = getState();
    const config = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.patch(`/api/categories/subcatupdate/${subcat._id}/`, subcat, config);
    
    dispatch({
      type: 'update_subcategory_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'update_subcategory_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

// Create SubCategory for admin panel
export const createSubCategoryAction = (subcat) => createAction(null, async (dispatch, getState) => {
  try {
    dispatch({ type: 'create_subcategory_request' });
    
    const { loginUser: { userInfo } } = getState();
    const config = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.post(`/api/categories/subcatcreate/`, subcat, config);
    
    dispatch({
      type: 'create_subcategory_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'create_subcategory_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});

// Delete SubCategory for admin panel
export const deleteSubCategoryAction = (id) => createAction(null, async (dispatch, getState) => {
  
  try {
    dispatch({ type: 'delete_subcategory_request' });
    
    const { loginUser: { userInfo } } = getState();
    const config = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${userInfo.token}`
      }
    };
    
    const { data } = await axios.delete(`/api/categories/subcatdelete/${id}/`, config);
    
    dispatch({
      type: 'delete_subcategory_success',
      payload: data
    });
  
  } catch (e) {
    dispatch({
      type: 'delete_subcategory_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message,
    });
  }
});