import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { userLoginSlice } from "../slices/userSlices";

export const loginUser = createAsyncThunk('loginUser', async (vals) => {
  
  const { email, password } = vals;
  
  const config = { headers: { 'Content-type': 'application/json' } }
  
  try {
    const { data } = await axios.post(
      '/api/users/login/',
      { 'username': email, 'password': password },
      config
    )

    localStorage.setItem('userInfo', JSON.stringify(data));
    
    return data
  } catch (e) {
    throw new Error(
      e.response && e.response.data.detail
        ? e.response.data.detail
        : (e.response.data.username ? e.response.data.username : e.message)
    )
  }
});

export const logoutuserAction = createAction('logoutUser', () => {
  localStorage.removeItem('userInfo');
  return {}
});

export const registerUserAction = createAsyncThunk('registerUser', async (vals) => {

  const { username, email, password } = vals;
  const config = { headers: { 'Content-type': 'application/json' } }

  try {
    const { data } = await axios.post(
      '/api/users/register/',
      { 'name': username, 'email': email, 'password': password },
      config
    )
    
    localStorage.setItem('userInfo', JSON.stringify(data));

    return data
  } catch (e) {
    throw new Error(
      e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message
    )
  }
});

export const getUserDetailsAction = () => createAction(null, async (dispatch, getState) => {
  try {
    dispatch({ type: 'user_details_request' });
    const { loginUser: { userInfo } } = getState();
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get('/api/users/profile/', config);
    
    dispatch({ type: 'user_details_success', payload: data });
  
  } catch (e) {
    dispatch({
      type: 'user_details_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message
    })
  }
});

export const updateUserProfileAction = (user) => createAction(null, async (dispatch, getState) => {
  try {
    
    const { loginUser: { userInfo } } = getState();
    
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.patch('/api/users/profile/update/', user, config);
    
    dispatch({ type: 'user_updateprofile_success', payload: data });
    
    dispatch(userLoginSlice.actions.loginUserProfile(data));
    
    localStorage.setItem('userInfo', JSON.stringify(data));

  } catch (e) {
    dispatch({
      type: 'user_updateprofile_failure',
      payload: e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message
    })
  }
});

export const userListAction = createAsyncThunk('userList', async (arg, { getState }) => {
  try {
    const { loginUser: { userInfo } } = getState();
    
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    
    const { data } = await axios.get('/api/users/', config);
    
    return data
    
  } catch (e) {
    throw new Error(
      e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message
    )
  }
});

// Get user profile by ID for admin panel
export const getUserByIdDetailsAction = createAsyncThunk('getUserByIdDetails', async (id, { getState }) => {
  try {
    const { loginUser: { userInfo } } = getState();
    
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    
    const { data } = await axios.get(`/api/users/${id}/`, config);
    
    return data
    
  } catch (e) {
    throw new Error(
      e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message
    )
  }
});

// Update user profile by ID for admin panel
export const updateUserAction = createAsyncThunk('updateUser', async (user, { dispatch, getState }) => {
  
  try {
    const { loginUser: { userInfo } } = getState();
    
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    
    const { data } = await axios.patch(`/api/users/update/${user._id}/`, user, config);
    
    dispatch({ type: 'userby_id_reset' });
    
    return data
    
  } catch (e) {
    throw new Error(
      e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message
    )
  }
});

export const deleteUserAction = createAsyncThunk('deleteUser', async (id, { getState }) => {

  try {
    const { loginUser: { userInfo } } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    
    const { data } = await axios.delete(`/api/users/delete/${id}/`, config);
    
    return data
  
  } catch (e) {
    throw new Error(
      e.response && e.response.data.detail
        ? e.response.data.detail
        : e.message
    )
  }
});