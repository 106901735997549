import React, { useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { addtoCart, removeFromCart } from '../actions/cartActions';
import Message from '../components/Message';
import LoadImage from '../components/LoadImage';

function CartScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const qty = location.search ? Number(location.search.split('=')[1]) : 1;
  
  const { cartItems } = useSelector(state => state.cart);
  
  useEffect(() => {
    id && dispatch(addtoCart(id, qty));
  }, [dispatch, id, qty])
  
  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
    navigate('/cart');
  }
  
  const checkoutHandler = () => {
    navigate('/login?redirect=/shipping')
  }
  
  return (
    <div>
      <div className='mb-3 ms-2'>
        <button type="button" onClick={() => navigate(-1)} style={{ left: '2rem' }}
          className="btn btn-sm btn-outline-success bg-gradient position-absolute" title='Go Back'>
          <i className="bi bi-arrow-left" /> Back
        </button>
      </div>
      <h1 className='text-center mt-2 mb-4 h2'>Shopping Cart</h1>
      {
        cartItems.length === 0 ? (
          <Message variant={'warning'} className='px-3'>
            Your cart is empty <Link to={'/'} className='text-decoration-none info-link fw-bolder'>Go to Home page</Link>
          </Message>
        ) : (
            <div className='row container mx-auto'>
              <div className="col-md-8">
                <div className='list-group-flush'>
                  {
                    cartItems.map(i => (
                      <div className='list-group-item mb-2' key={i._id}>
                        <div className='row'>
                          <div className="col-2">
                            <LoadImage src={`/static${i.image}`} className='img' style={{ maxWidth: '80%', height: 'auto' }} />
                            {/* <img className="img"
                              style={{ maxWidth: '70%', height: 'auto' }}
                              src={`/static/${i.image}`}
                              alt="" /> */}
                          </div>
                          <div className="col-md-4 mt-1">
                            <Link to={`/${i.catSlug}/${i.subcatSlug}/${i._id}`} target='_blank'
                              className="small text-decoration-none cat-header-link">{i.title}</Link>
                          </div>
                          <div className="col-md-2 mt-2">{ i.price }$</div>
                          <div className="col-md-2 mt-2">
                            <select className="form-select text-success fw-bolder"
                              onChange={(e) => dispatch(addtoCart(i.item, Number(e.target.value)))}>
                              <option hidden="" value={`${i.qty}`}>{i.qty}</option>
                              {
                                [...Array(i.countInStock).keys()].map((x) => (
                                  <option key={x + 1} value={x + 1}>{x + 1}</option>
                                ))
                              }
                            </select>
                          </div>
                          <div className="col ms-4 mt-2">
                            <button type="button" className="btn p-0 cart-trashbtn"
                              onClick={() => removeFromCartHandler(i._id)}>
                              <i className="bi bi-trash3 fs-4"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>

              <div className="col-md-4">
                <div className="card pb-3">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item text-center">
                      <h2 className="h5">
                        Subtotal
                        <strong className="text-success mx-2 fs-4">
                          {cartItems.reduce((a, i) => a + i.qty, 0)}
                        </strong>
                        items
                      </h2>
                      <p className='mt-4'>
                        For pay:
                        <span className='text-success ms-3' style={{ letterSpacing: '0.02em', fontWeight: '500' }}>
                          {cartItems.reduce((a, i) => a + i.qty * i.price, 0).toFixed(2)}$
                        </span>
                      </p>
                    </div>
                    <div className="text-center list-group-item">
                      <button type="button" className="btn btn-info bg-gradient"
                        onClick={checkoutHandler}>Proceed To Checkout</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )
      }

    </div>
  )
}

export default CartScreen;