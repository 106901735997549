import { createSlice } from "@reduxjs/toolkit";

export const addtoCartSlice = createSlice({
  name: 'addtoCart',
  initialState: { cartItems: [], shippingAddress: Object },
  extraReducers: (builder) => {
    builder
      .addCase('cart_add_item', (state, action) => {
        const vals = action.payload;
        const existVals = state.cartItems.find(x => x._id === vals._id);
        
        if (existVals) {
          return {
            ...state,
            cartItems: state.cartItems.map(v => v._id === existVals._id ? vals : v)
          }
        } else {
          return {
            ...state,
            cartItems: [...state.cartItems, vals]
          }
        }
      })
      .addCase('cart_remove_item', (state, action) => {
        return {
          ...state,
          cartItems: state.cartItems.filter(x => x._id !== action.payload)
        }
      })
      .addCase('save_shipping_address', (state, action) => {
        return {
          ...state,
          shippingAddress: action.payload
        }
      })
      .addCase('save_payment_method', (state, action) => {
        return {
          ...state,
          paymentMethod: action.payload
        }
      })
      .addCase('clear_cart_items', (state) => {
        return {
          ...state,
          cartItems: []
        }
      })
      .addDefaultCase((state) => {
        return state
      }) 
  }
})