import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function PreHeader() {
  const navigate = useNavigate();
  const ref = useRef(null);

  const [keyword, setKeyword] = useState('');
  
  const submitHandler = (e) => {
    e.preventDefault();
    keyword && navigate({ pathname: '/search', search: `q=${keyword}` })
    ref.current.reset();
  }
  
  const shareHandler = (link) => {
    window.open(link);
  }
  
  return (
    <div>
      <div className="border-bottom border-secondary overflow-hidden mb-3 pb-1 preheader"
        style={{ padding: '0 8rem' }}>
        <Link to={'/'}>
          <div className='float-start' style={{ minWidth: '35%' }}>
            <img src='/static/images/logo.svg' height={50}
              className='me-sm-3 mt-lg-3 mt-xl-4'
              style={{ float: 'left' }} loading='lazy' alt='logo' />
            <h1 className='mt-4 text-light'
              style={{ fontFamily: 'Source Sans Pro', fontWeight: '600' }}>
              Buy Simply Goods
            </h1>
          </div>
        </Link>
        
        <div className='mt-4 float-start searchfield' style={{ marginLeft: '9%' }}>
          <form className="d-flex" onSubmit={submitHandler} ref={ref}>
            <button className='btn btn-secondary bg-gradient searchButton shadow-none'>
              <i className='bi bi-search' />
            </button>
            <input onChange={(e) => setKeyword(e.target.value)}
              className="form-control w-75 inputSearch" type="text" placeholder="Search" />
          </form>
        </div>
        <div className='socNetwork float-end mt-sm-4'>
          <ul style={{ listStyle: 'none' }}>
            <li>
              <Link to={'#'} className='fs-3' title='Share to WhatsApp'
                onClick={() => shareHandler(`https://api.whatsapp.com/send?text=${window.location.href}`)}>
                <i className="bi bi-whatsapp text-light" />
              </Link>
            </li>
            <li>
              <Link to={'#'} className='fs-3' title='Share to Facebook'
                onClick={() => shareHandler(`https://www.facebook.com/sharer.php?u=${window.location.href}`)}>
                <i className="bi bi-facebook text-light" />
              </Link>
            </li>
            <li>
              <Link to={'#'} className='fs-3' title='Share to Twitter'
                onClick={() => shareHandler(`https://twitter.com/share?url=${window.location.href}`)}>
                <i className="bi bi-twitter text-light" />
              </Link>
            </li>
            <li>
              <Link to={'#'} className='fs-3' title='Share to Pinterest'
                onClick={() => shareHandler(`https://pinterest.com/pin/create/bookmarklet/?url=${window.location.href}`)}>
                <i className="bi bi-pinterest text-light" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default React.memo(PreHeader);