import { createSlice } from "@reduxjs/toolkit";

export const createOrderSlice = createSlice({
  name: 'createOrder',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('order_create_request', (state, action) => {
        return {
          loading: true
        }
      })
      .addCase('order_create_success', (state, action) => {
        return {
          loading: false,
          order: action.payload
        }
      })
      .addCase('order_create_failure', (state, action) => {
        return {
          loading: false,
          error: action.payload
        }
      })
      .addCase('order_create_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const orderDetailsSlice = createSlice({
  name: 'orderDetails',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('order_details_request', (state) => {
        state.loading = true
      })
      .addCase('order_details_success', (state, action) => {
        state.loading = false
        state.order = action.payload
      })
      .addCase('order_details_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const listMyOrdersSlice = createSlice({
  name: 'listMyOrders',
  initialState: {orders: []},
  extraReducers: (builder) => {
    builder
      .addCase('order_list_request', (state) => {
        state.loading = true
      })
      .addCase('order_list_success', (state, action) => {
        state.loading = false
        state.orders = action.payload
      })
      .addCase('order_list_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('order_list_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const payOrderSlice = createSlice({
  name: 'payOrder',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('order_pay_request', (state) => {
        state.loading = true
      })
      .addCase('order_pay_success', (state, action) => {
        state.loading = false
        state.success = true
      })
      .addCase('order_pay_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('order_pay_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const deliverOrderlice = createSlice({
  name: 'deliverOrder',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('order_deliver_request', (state) => {
        state.loading = true
      })
      .addCase('order_deliver_success', (state, action) => {
        state.loading = false
        state.success = true
      })
      .addCase('order_deliver_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('order_deliver_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

// List orders for admin panel
export const listOrdersSlice = createSlice({
  name: 'listOrders',
  initialState: {orders: []},
  extraReducers: (builder) => {
    builder
      .addCase('list_orders_request', (state) => {
        state.loading = true
      })
      .addCase('list_orders_success', (state, action) => {
        state.loading = false
        state.orders = action.payload.orders
        state.page = action.payload.page
        state.pages = action.payload.pages
      })
      .addCase('list_orders_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('list_orders_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});