import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { listCategories, deleteCategoryAction } from '../actions/categoryActions';
import LoadImage from '../components/LoadImage';
import Message from '../components/Message';
import Loader from '../components/Loader';

function CategoryListScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { userInfo } = useSelector(state => state.loginUser);
  const { loading, category, error } = useSelector(state => state.categoryList);
  const { 
    loading: deleteLoading, response, error: deleteErrror
   } = useSelector(state => state.deleteCategory);
  
  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      dispatch(deleteCategoryAction(id));
    }
  }
  
  useEffect(() => {
    ((userInfo && userInfo.isadmin) || response) ? dispatch(listCategories()) : navigate('/');
    return () => {
      response && dispatch({ type: 'delete_category_reset' });
    }
  }, [dispatch, navigate, userInfo, response]);
  
  return (
    <div>
      <div style={{ width: 'fit-content' }}>
        <button type="button" onClick={() => navigate('/rootuser/categorylist/0')} title='Create Category' style={{ right: '10rem' }}
          className="btn btn-sm btn-outline-info bg-gradient position-absolute">
          Create New Category
        </button>
      </div>
      <h1 className='text-center my-2 h2 fst-italic text-light'>Admin Category List</h1>
      <div className="col border-secondary container mt-3">
        <div className="table-responsive">
          {deleteLoading && <Loader />}
          {deleteErrror && <Message variant={'danger'}>{deleteErrror}</Message>}
          {response && <Message>{response}</Message>}
          {
            loading ? <Loader /> : (error ? <Message variant={'danger'}>{error.message}</Message> : (
              <table className="table table-striped table-bordered">
                <thead>
                  <tr className='text-success text-center'>
                    <th scope="col">ID</th>
                    <th scope="col">Title</th>
                    <th scope="col">Meta Description</th>
                    <th scope="col">Slug</th>
                    <th scope="col">Image</th>
                    <th scope="col">Created</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    category.map((cat) => (
                      <tr key={cat._id}>
                        <td>{cat._id}</td>
                        <td>{cat.title}</td>
                        <td>{cat.metadesc}</td>
                        <td>{cat.slug}</td>
                        <td><LoadImage src={`/static${cat.image}`} height={40} /></td>
                        <td>{String(cat.created).substring(0, 10)}</td>
                        <td className='col text-center pt-3'>
                          <Link to={`/rootuser/categorylist/${cat._id}`}
                            state={{
                              title: cat.title,
                              metadesc: cat.metadesc,
                              slug: cat.slug,
                              img: cat.image
                            }}
                            title='Edit Category' className='me-3'>
                            <button type="button"
                              style={{ padding: '0.2rem', fontSize: '0.9em' }}
                              className="btn btn-sm btn-outline-success bg-gradient">
                              <i className="bi bi-pencil me-1" />
                              Edit
                            </button>
                          </Link>
                          <button type="button" title='Delete Category'
                            onClick={() => deleteHandler(cat._id)}
                            onMouseUp={(e) => e.target.blur()}
                            style={{ padding: '0.1rem 0.2rem 0.2rem 0.2rem', fontSize: '0.9em' }}
                            className="btn btn-sm btn-outline-danger bg-gradient">
                            <i className="bi bi-trash3 me-1" />
                            Delete
                          </button>
                          <Link to={`/rootuser/subcategorylist`}
                            state={{
                              catname: cat.title,
                              subcats: cat.subcategory
                            }}
                            title='Show Subcategories' className='ms-3'>
                            <button type="button"
                              style={{ padding: '0.2rem', fontSize: '0.9em' }}
                              className="btn btn-sm btn-outline-light bg-gradient">
                              <i className="bi bi-bookmarks me-1" />
                              Subcategories
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default CategoryListScreen;