import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { userListAction, deleteUserAction } from '../actions/userActions';
import Message from '../components/Message';
import Loader from '../components/Loader';

function UserListScreen() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { userInfo } = useSelector(state => state.loginUser);
  const {loading, users, error} = useSelector(state => state.userList);
  const { successDelete } = useSelector(state => state.deleteUser);
  
  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      dispatch(deleteUserAction(id));
    }
  }
  
  useEffect(() => {
    (userInfo && userInfo.isadmin) ? dispatch(userListAction()) : navigate('/');
    successDelete && dispatch({ type: 'user_delete_reset' });
  }, [dispatch, navigate, userInfo, successDelete]);
  
  return (
    <div>
      <h1 className='text-center my-2 h2 fst-italic text-light'>Admin Users List</h1>
      <div className='col border-secondary container mt-3'>
        <div className="table-responsive">
          {
            loading ? <Loader /> : (
              error ? <Message variant={'danger'}>{error}</Message> : (
                <table className="table table-striped table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Admin</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      users.map((u) => (
                        <tr key={u.id}>
                          <td>{u.id}</td>
                          <td>{u.name}</td>
                          <td>{u.email}</td>
                          <td>
                            {
                              u.isadmin ? (
                                <div className='text-success'>
                                  <span className='me-4'>Admin</span>
                                  <i className="bi bi-person-circle fs-5"/>
                                </div>
                              ): (
                                <div className='text-warning'>
                                  <span className='me-1'>NoAdmin</span>
                                  <i className="bi bi-person fs-5"/>
                                </div>
                              )
                            }
                          </td>
                          <td>
                            <Link to={`/rootuser/userlist/${u.id}`} className='me-3' title='Edit User'>
                              <button type="button"
                                style={{ padding: '0.1rem 0.2rem 0.2rem 0.2rem', fontSize: '1em' }}
                                className="btn btn-outline-success bg-gradient">
                                Edit User
                              </button>
                            </Link>
                            <button type="button" title='Delete User'
                              onClick={() => deleteHandler(u.id)}
                              style={{ padding: '0.1rem 0.2rem 0.2rem 0.2rem', fontSize: '1em' }}
                              className="btn btn-outline-danger bg-gradient">
                              Delete User
                            </button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              )
            )
          }
        </div>
      </div>
    </div>
  )
}

export default UserListScreen;