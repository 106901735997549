import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getSubcatItems } from '../actions/categoryActions';
import Item from '../components/Item';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Filter from '../components/Filter';
import Paginate from '../components/Paginate';

function SubcategoryScreen() {
  
  const { catname, subcatname } = useParams();
  const dispatch = useDispatch();
  const navigateBack = useNavigate();
  const location = useLocation();

  let keyword = location.search;
  const childurl = location.pathname.substring(1);
  
  const { list, loading, error, page, pages } = useSelector(state => state.getSubcatItems);
  
  useEffect(() => {
    dispatch(getSubcatItems({ catname, subcatname, keyword }));
  }, [dispatch, catname, subcatname, keyword]);
  
  return (
    <div className='px-5 py-2'>
      <div className='mb-3 ms-3'>
        <button type="button" onClick={() => navigateBack(-1)} title='Go Back' style={{ left: '2rem' }}
          className="btn btn-sm btn-outline-success bg-gradient position-absolute">
          <i className="bi bi-arrow-left" /> Back
        </button>
      </div>
      <Filter />
      <h1 className={`text-center mb-5 h2 catname ${
        (list.length === 0 && !loading) ? 'text-warning' : 'text-light fst-italic'}`}>
        {list && (list[0] ? list[0].subcattitle : (!loading && 'There stilll no items herre'))}
      </h1>
      {
        loading ? <Loader /> : (
          error ? <Message variant={'dnager'}>{error.message}</Message> : (
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-4 g-lg-3 g-md-2 justify-content-center'>
              {
                list.map(i => (
                  <div className='col catblock' key={i._id}>
                    <Item item={i} subcat={true} />
                  </div>
                ))
              }
            </div>
          )
        )
      }
      {
        pages > 1 && (
          <Paginate
            page={page} pages={pages} keyword={keyword}
            childurl={childurl ? childurl : false} />
        )
      }
    </div>
  )
}

export default React.memo(SubcategoryScreen);