import { createSlice } from "@reduxjs/toolkit";

import { itemDetail } from "../actions/itemActions";

export const itemDetailSlice = createSlice({
  name: 'itemDetail',
  initialState: { list: [] },
  extraReducers: (builder) => {
    builder
      .addCase(itemDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(itemDetail.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading = false;
      })
      .addCase(itemDetail.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addDefaultCase((state) => {
        state.list = [];
      })
  }
});

export const reviewItemSlice = createSlice({
  name: 'reviewItem',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('review_create_request', (state) => {
        state.loading = true
      })
      .addCase('review_create_success', (state, action) => {
        state.loading = false
        state.response = action.payload
      })
      .addCase('review_create_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('review_create_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const getNewcollectionSlice = createSlice({
  name: 'getNewcollection',
  initialState: { newcollection: [] },
  extraReducers: (builder) => {
    builder
      .addCase('newcollection_create_request', (state) => {
        state.loading = true
      })
      .addCase('newcollection_create_success', (state, action) => {
        state.loading = false
        state.newcollection = action.payload
      })
      .addCase('newcollection_create_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('newcollection_create_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const getAllItemsSlice = createSlice({
  name: 'getAllItems',
  initialState: { items: [] },
  extraReducers: (builder) => {
    builder
      .addCase('get_allitems_request', (state) => {
        state.loading = true
      })
      .addCase('get_allitems_success', (state, action) => {
        state.loading = false
        state.items = action.payload.item
        state.page = action.payload.page
        state.pages = action.payload.pages
      })
      .addCase('get_allitems_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('get_allitems_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const updateItemSlice = createSlice({
  name: 'updateItem',
  initialState: { item: {} },
  extraReducers: (builder) => {
    builder
      .addCase('update_item_request', (state) => {
        state.loading = true
      })
      .addCase('update_item_success', (state, action) => {
        state.loading = false
        state.item = action.payload
        state.success = true
      })
      .addCase('update_item_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('update_item_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const deleteItemSlice = createSlice({
  name: 'deleteItem',
  initialState: { },
  extraReducers: (builder) => {
    builder
      .addCase('delete_item_request', (state) => {
        state.loading = true
      })
      .addCase('delete_item_success', (state, action) => {
        state.loading = false
        state.response = action.payload
      })
      .addCase('delete_item_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('delete_item_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});

export const createItemSlice = createSlice({
  name: 'createItem',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('create_item_request', (state) => {
        state.loading = true
      })
      .addCase('create_item_success', (state, action) => {
        state.loading = false
        state.successCreate = action.payload && true
      })
      .addCase('create_item_failure', (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase('create_item_reset', () => {
        return {}
      })
      .addDefaultCase((state) => {
        return state
      })
  }
});